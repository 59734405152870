// components/columnBuilder.js

import React from 'react'
import { Type } from 'react-bootstrap-table2-editor'
import { Input } from 'reactstrap' // Import Input component if using reactstrap or similar library
import { safeDeepGet } from 'iotera-base/utility/json'

const ColumnsPrice = productMap => {
  const productList = []
  for (const sku in productMap) {
    productList.push({
      label: productMap[sku],
      value: sku,
    })
  }
  const mobile = [
    {
      dataField: 'selection',
      text: 'Selection',
      editable: false,
      style: (cell, row) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },

      editCellStyle: (cell, row) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
    {
      dataField: 'price',
      text: 'Price (Rp)',
      validator: (newValue) => {
        if (!/^\d+$/.test(newValue)) {
          return {
            valid: false,
          }
        }
        return true
      },
      style: (cell, row) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
      editCellStyle: (cell, row) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
    {
      dataField: 'active',
      text: 'Active',
      editor: {
        type: Type.CHECKBOX,
      },
      formatter: (cell, row) => {
        return (
          <Input
            type="checkbox"
            defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
          />
        )
      },
      style: (cell, row) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
      editCellStyle: (cell, row) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
  ]
  const price = mobile
  return price
}

export default ColumnsPrice
