/* eslint-disable semi */
import React from 'react';
// Strap
import { Card, CardBody, CardTitle, Table } from 'reactstrap';

// Table
// Breadcrumbs
// Toast
import 'toastr/build/toastr.min.css';
// Iotera
import { APPLICATION_ID } from 'Apps';
// Redux
import PropTypes from 'prop-types';

class VMLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClik: false,
    };
  }

  static propTypes = {
    type: PropTypes.string,
    dataColumn: PropTypes.object,
  };
  static defaultProps = {
    type: '',
    dataColumn: {},
  };

  render() {
    const { type, dataColumn } = this.props;
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4 h5">VM Layout</CardTitle>
          <p className="card-title-desc">
            Layout kolom mesin (referensi penempatan SKU)
          </p>
          <div className="card bg-primary text-white mb-0">
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-sm mb-0 text-white text-center">
                  <thead>
                    {APPLICATION_ID == 'Redboks' ? (
                      <tr>
                        <th colSpan="10">Kubota Top</th>
                      </tr>
                    ) : (
                      <tr>
                        <th colSpan="10">Top</th>
                      </tr>
                    )}
                  </thead>
                  {APPLICATION_ID == 'Redboks' ? (
                    <tbody>
                      <tr>
                        <td>K1</td>
                        <td>K6</td>
                        <td>K11</td>
                        <td>K16</td>
                      </tr>
                      <tr>
                        <td>K2</td>
                        <td>K7</td>
                        <td>K12</td>
                        <td>K17</td>
                      </tr>
                      <tr>
                        <td>K3</td>
                        <td>K8</td>
                        <td>K13</td>
                        <td>K18</td>
                      </tr>
                      <tr>
                        <td>K4</td>
                        <td>K9</td>
                        <td>K14</td>
                        <td>K19</td>
                      </tr>
                      <tr>
                        <td>K5</td>
                        <td>K10</td>
                        <td>K15</td>
                        <td>K20</td>
                      </tr>
                    </tbody>
                  ) : type == 'kubota' ? (
                    <tbody>
                      <tr>
                        <td>K1</td>
                        <td>K6</td>
                        <td>K11</td>
                        <td>K16</td>
                      </tr>
                      <tr>
                        <td>K2</td>
                        <td>K7</td>
                        <td>K12</td>
                        <td>K17</td>
                      </tr>
                      <tr>
                        <td>K3</td>
                        <td>K8</td>
                        <td>K13</td>
                        <td>K18</td>
                      </tr>
                      <tr>
                        <td>K4</td>
                        <td>K9</td>
                        <td>K14</td>
                        <td>K19</td>
                      </tr>
                      <tr>
                        <td>K5</td>
                        <td>K10</td>
                        <td>K15</td>
                        <td>K20</td>
                      </tr>
                    </tbody>
                  ) : type == 'nagomi' ? (
                    <tbody>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 5 ? null : <td key={'A' + i}>{item.key}</td>}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 5 ? (
                                i < 12 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 11 ? (
                                i < 19 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 18 ? (
                                i < 26 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 25 ? (
                                i < 33 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                    </tbody>
                  ) : type == 'slant' ? (
                    <tbody>
                      <tr>
                        <td>K1</td>
                        <td>K7</td>
                        <td>K13</td>
                        <td>K19</td>
                        <td>K28</td>
                      </tr>
                      <tr>
                        <td>K2</td>
                        <td>K8</td>
                        <td>K14</td>
                        <td>K20</td>
                        <td>K29</td>
                      </tr>
                      <tr>
                        <td>K3</td>
                        <td>K9</td>
                        <td>K15</td>
                        <td>K21</td>
                        <td>K30</td>
                      </tr>
                      <tr>
                        <td>K4</td>
                        <td>K10</td>
                        <td>K16</td>
                        <td>K22</td>
                        <td>K31</td>
                      </tr>
                      <tr>
                        <td>K5</td>
                        <td>K11</td>
                        <td>K17</td>
                        <td>K23</td>
                        <td>K32</td>
                      </tr>
                      <tr>
                        <td>K6</td>
                        <td>K12</td>
                        <td>K18</td>
                        <td>K24</td>
                        <td>K33</td>
                      </tr>
                      <tr>
                        <td>{'⁣'}</td>
                        <td>{'⁣'}</td>
                        <td>{'⁣'}</td>
                        <td>K25</td>
                        <td>K34</td>
                      </tr>
                      <tr>
                        <td>{'⁣'}</td>
                        <td>{'⁣'}</td>
                        <td>{'⁣'}</td>
                        <td>K26</td>
                        <td>K35</td>
                      </tr>
                      <tr>
                        <td>{'⁣'}</td>
                        <td>{'⁣'}</td>
                        <td>{'⁣'}</td>
                        <td>K27</td>
                        <td>K36</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 9 ? null : <td key={'A' + i}>{item.key}</td>}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 9 ? (
                                i < 20 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 19 ? (
                                i < 30 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 29 ? (
                                i < 40 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 39 ? (
                                i < 50 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 49 ? (
                                i < 60 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        {dataColumn.map(function (item, i) {
                          return (
                            <>
                              {i > 59 ? (
                                i < 70 ? (
                                  <td key={'A' + i}>{item.key}</td>
                                ) : null
                              ) : null}
                            </>
                          );
                        })}
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </CardBody>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default VMLayout;
