/* eslint-disable semi */
import React from 'react';
// Strap
import { Card, CardBody, CardTitle, Table } from 'reactstrap';

// Table
// Breadcrumbs
// Toast
import 'toastr/build/toastr.min.css';
// Iotera
// Redux
import PropTypes from 'prop-types';

class ColumnLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClik: false,
    };
  }

  static propTypes = {
    dataButton: PropTypes.object,
  };
  static defaultProps = {
    dataButton: {},
  };

  render() {
    const { dataButton } = this.props;
    return (
      <Card>
      <CardBody>
        <CardTitle className="mb-4 h5">Column Layout</CardTitle>
        <p className="card-title-desc">Layout kolom mesin</p>
        <div className="card bg-primary text-white mb-0">
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-sm mb-0 text-white text-center">
                <thead>
                  <tr>
                    <th colSpan="10">Column</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {dataButton.map(function (item, i) {
                      return (
                        <>
                          {i > 19 ? (
                            i < 30 ? (
                              <td key={'A' + i}>{item.key}</td>
                            ) : null
                          ) : null}
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    {dataButton.map(function (item, i) {
                      return (
                        <>
                          {i > 9 ? (
                            i < 20 ? (
                              <td key={'A' + i}>{item.key}</td>
                            ) : null
                          ) : null}
                        </>
                      );
                    })}
                  </tr>
                  <tr>
                    {dataButton.map(function (item, i) {
                      return (
                        <>
                          {i > 9 ? null : (
                            <td key={'A' + i}>{item.key}</td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </div>
      </CardBody>
    </Card>
    );
  }
}

export default ColumnLayout;
