/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';
import { Buffer } from 'buffer';
// Product state
import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  PAYOUT_LIST,
  ACCOUNT_LIST,
  PAYOUT_CREATE,
  DETAIL_ACCOUNT,
  GET_BALANCE,
  PAYOUT_REQUEST,
  PAYOUT_VALIDATE,
  POST_USER_DATA,
  PAYOUT_CANCEL,
} from './actionTypes';
import {
  account_bank_list,
  bank_list,
  payout_list,
  getBalance,
  payoutValidate,
  payoutRequest,
  postPayoutData,
  deletePayoutData,
  getPayoutData,
} from 'Apps/device';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import Result from 'iotera-base/result';
import { FIREBASE_URL } from 'Apps';
import { getDatabase, ref, set, onValue, remove } from 'firebase/database';
const db = getDatabase();

const MULTITENANCY = JSON.parse(
  localStorage.getItem('multitenancy') || 'false'
);
const APPLICATION_ID = localStorage.getItem('application_id');
let balanc = {};
function* get_Balance() {
  yield put(loading(GET_BALANCE));
  let wsResult = yield call(getBalance);
  const tag = localStorage.getItem('tag') ?? JSON.parse(null);
  // const tag = 'aj9'
  if (safeDeepGet(wsResult, 'code') == 200) {
    balanc = safeDeepGet(wsResult, 'body', {});
    const firebaseInstance = getInstance();
    wsResult = yield call(firebaseInstance.balance.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      let balanceBody = safeDeepGet(wsResult, ['body', 'data'], {});
      if (JSON.parse(localStorage.getItem('isMultiBalance'))) {
        if (tag != null || tag != 'null') {
          balanceBody = safeDeepGet(wsResult, ['body', `balance_${tag}`], {});
        }
      }
      const balance = safeDeepGet(balanceBody, 'balance', 0);
      const roundDown = Math.floor(balance);
      safeDeepSet(balanceBody, 'balance', roundDown);
      yield put(
        result(GET_BALANCE, { code: Result.SUCCESS, body: balanceBody })
      );
      return;
    } else {
      yield put(result(GET_BALANCE, { code: Result.FAILED }));
    }
  } else {
    yield put(result(GET_BALANCE, { code: Result.FAILED }));
  }
}

function* postData(action) {
  yield put(loading(POST_USER_DATA)); // Set loading state
  // Extracting payload data
  const amount = Number(safeDeepGet(action, ['payload', 'amount']));
  const account_id = safeDeepGet(action, ['payload', 'id']);
  const account_bank = safeDeepGet(action, ['payload', 'bank']);
  const account_no = safeDeepGet(action, ['payload', 'rekening']);
  const account_name = safeDeepGet(action, ['payload', 'name'], '');

  // Retrieve the authenticated user from localStorage
  const storage = localStorage.getItem('authUser');
  const tag = localStorage.getItem('tag');
  const jsonStorage = JSON.parse(storage);
  const authUser = safeDeepGet(jsonStorage, 'email', '-');
  const phoneNumber = localStorage.getItem('phoneNumber');

  // Prepare data to save
  const payoutData = {
    amount,
    account_id,
    account_bank,
    account_no,
    account_name,
    authUser,
    otp_delivery_method: 'WHATSAPP',
    wa_receiver: phoneNumber,
    email_receiver: authUser,
    application_id: APPLICATION_ID,
    multitenancy: MULTITENANCY,
  };

  if (JSON.parse(localStorage.getItem('isMultiBalance'))) {
    payoutData['tag']= tag
  }
  payoutData['isMultiBalance'] = JSON.parse(localStorage.getItem("isMultiBalance"))?? false;
  // Call the helper function to save the data in Firebase
  try {
    const wsResult = yield call(postPayoutData, payoutData);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.HTTP.OK) {
      const body = safeDeepGet(wsResult, 'body', {});
      yield put(result(POST_USER_DATA, { code: Result.SUCCESS, body }));
    }
  } catch (error) {
    yield put(result(POST_USER_DATA, { code: Result.FAILED, message: error }));
  }

  // Dispatch success action if all goes well
}

function* payout_create(payload) {
  yield put(loading(PAYOUT_CREATE));

  const storage = localStorage.getItem('authUser');
  const jsonStorage = JSON.parse(storage);
  const authUser = safeDeepGet(jsonStorage, 'email', '-');

  const idPayout = safeDeepGet(payload, ['payload', 'idPayout'], '');
  let wsResult = yield call(getPayoutData, idPayout);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const body = safeDeepGet(wsResult, ['body', 'data'], {});
    safeDeepSet(body, 'payoutId', idPayout);
    safeDeepSet(body, 'application_id', APPLICATION_ID);
    safeDeepSet(body, 'multitenancy', MULTITENANCY);
    if(localStorage.getItem('tag')!= undefined && localStorage.getItem('tag') != null){
      safeDeepSet(body, 'tag', localStorage.getItem('tag'))
    }
    wsResult = yield call(create_payout, body);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      yield put(result(PAYOUT_CREATE, { code: Result.SUCCESS }));
    } else if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      yield put(result(PAYOUT_CREATE, { code: Result.SUCCESS }));
    } else {
      yield put(result(PAYOUT_CREATE, { code: Result.FAILED }));
    }
  } else {
    yield put(result(PAYOUT_CREATE, { code: Result.FAILED }));
  }
}

function create_payout(body) {
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  var result = JPOST({
    timeout: 60000,
    url: FIREBASE_URL + `/payout`,
    headers: {
      Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
    },
    body: {
      data: btoaBody,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}
function validate_payout(body) {
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  var result = JPOST({
    timeout: 60000,
    url: FIREBASE_URL + `/payoutValidate`,
    headers: {
      Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
    },
    body: {
      data: btoaBody,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}
function cancel_payout(data) {
  safeDeepSet(data, 'data', {
    method: 'MIDTRANS',
  });

  var result = JPOST({
    timeout: 60000,
    url: FIREBASE_URL + `/payoutCancel`,
    headers: {
      Authorization: 'apikey ajkhjkhqkjhwjkehkqwe:khkkjqhjkwhjkq',
    },
    body: data,
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}

function* payoutlist(payload) {
  yield put(loading(PAYOUT_LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.payout.list, payload);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const payoutBody = wsResult.body;
    const payoutMap = {};
    const payoutList = [];
    let i = 0;
    for (const id in payoutBody) {
      safeDeepSet(payoutBody, [id, 'id'], i++);
      safeDeepSet(payoutBody, [id, 'payoutId'], id);
      payoutMap[i++] = safeDeepGet(payoutBody, id);
    }
    wsResult = yield call(bank_list);
    if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
      const bankBody = safeDeepGet(wsResult, ['body', 'banks']);
      const bankMap = {};
      for (const id in bankBody) {
        const code = bankBody[id].code;
        bankMap[code] = bankBody[id];
      }
      for (const id in payoutMap) {
        const codebank = safeDeepGet(payoutMap, [id, 'account', 'acc_bank']);
        const NameBank = safeDeepGet(bankMap, [codebank, 'name']);
        safeDeepSet(payoutMap, [id, 'bank'], NameBank);
        payoutList.push(payoutMap[id]);
      }
    }
    yield put(result(PAYOUT_LIST, { code: Result.SUCCESS, body: payoutList }));
    return;
  }
  yield put(result(PAYOUT_LIST, { result: { code: Result.FAILED } }));
}

function* detailAccount(payload) {
  const filterId = safeDeepGet(payload, 'payload');
  yield put(loading(DETAIL_ACCOUNT));
  let wsResult = yield call(account_bank_list);
  if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
    let accountMap = safeDeepGet(wsResult, ['body', 'data'], []);
    wsResult = yield call(bank_list);
    const accountList = [];
    if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
      const bankBody = safeDeepGet(wsResult, ['body', 'banks']);
      const bankMap = {};
      for (const id in bankBody) {
        const code = bankBody[id].code;
        bankMap[code] = bankBody[id];
      }
      for (const id in accountMap) {
        const accountid = accountMap[id].account_id;
        const jsonBank = accountMap[id];
        const codebank = jsonBank.account_bank;
        const NameBank = safeDeepGet(bankMap, [codebank, 'name']);
        safeDeepSet(jsonBank, ['bank'], NameBank);
        if (accountid == filterId) {
          accountList.push(accountMap[id]);
        }
      }
    }
    yield put(
      result(DETAIL_ACCOUNT, { code: Result.SUCCESS, body: accountList })
    );
    return;
  }
  yield put(result(DETAIL_ACCOUNT, { result: { code: Result.FAILED } }));
}
function* list() {
  yield put(loading(LIST));
  let wsResult = yield call(account_bank_list);
  if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
    const code = safeDeepGet(wsResult, ['body', 'data', 'status_code']);
    let accountMap = safeDeepGet(wsResult, ['body', 'data'], []);
    if (code == 0) {
      accountMap = safeDeepGet(wsResult, ['body', 'data', 'data'], []);
    }
    wsResult = yield call(bank_list);
    const accountList = [];
    if (safeDeepGet(wsResult, ['body', 'status_code']) == Result.SUCCESS) {
      let bankBody = safeDeepGet(wsResult, ['body', 'banks']);
      const code = safeDeepGet(wsResult, ['body', 'data', 'status_code']);
      if (code == 0) {
        bankBody = safeDeepGet(wsResult, ['body', 'data', 'banks'], []);
      }
      const bankMap = {};
      for (const id in bankBody) {
        const code = bankBody[id].code;
        bankMap[code] = bankBody[id];
      }
      for (const id in accountMap) {
        const jsonBank = accountMap[id];
        const codebank = jsonBank.account_bank;
        const NameBank = safeDeepGet(bankMap, [codebank, 'name']);
        safeDeepSet(jsonBank, ['bank'], NameBank);
        accountList.push(accountMap[id]);
      }
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: accountList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(loading(INIT_CREATE));
  const wsResult = yield call(bank_list);

  let bankBody = safeDeepGet(wsResult, ['body', 'banks']);
  const code = safeDeepGet(wsResult, ['body', 'data', 'status_code']);
  if (code == 0) {
    bankBody = safeDeepGet(wsResult, ['body', 'data', 'banks'], []);
  }
  const bankFilterList = [{ label: 'All Bank', value: 'all' }];
  for (const i in bankBody) {
    const value = bankBody[i].code;
    const label = bankBody[i].name;
    bankFilterList.push({ label: label, value: value });
  }
  yield put(
    result(INIT_CREATE, { code: Result.SUCCESS, body: bankFilterList })
  );
}

function* accountList() {
  yield put(loading(ACCOUNT_LIST));
  const wsResult = yield call(account_bank_list);
  const code = safeDeepGet(wsResult, ['body', 'data', 'status_code']);
  let accountBody = safeDeepGet(wsResult, ['body', 'data'], []);
  if (code == 0) {
    accountBody = safeDeepGet(wsResult, ['body', 'data', 'data'], []);
  }
  const accountFillterList = [
    { label: 'All Account', value: 'all' },
    // { label: 'Test Account', value: 'ab777997-7e14-4456-b7a5-383c3d6d03fc' },
  ];
  for (const i in accountBody) {
    const value = accountBody[i].account_id;
    const label = accountBody[i].account_name;
    accountFillterList.push({ label: label, value: value });
  }
  yield put(
    result(ACCOUNT_LIST, { code: Result.SUCCESS, body: accountFillterList })
  );
}

function createAccount(accountNo, name, email, selectedBank) {
  const body = {
    account_name: name,
    account_no: accountNo,
    account_bank: selectedBank,
    account_email: email,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = {
    data: btoaBody,
  };
  var result = JPOST({
    url:
      'https://serverless-api.iotera.io/api/' +
      APPLICATION_ID +
      '/account-create',
    body: {
      data: btoaBody,
    },
    timeout: 60000,
  });

  return result;
}

function* create(action) {
  yield put(loading(CREATE));
  const accountNo = safeDeepGet(action, ['payload', 'accountNo']);
  const email = safeDeepGet(action, ['payload', 'email']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const selectedBank = safeDeepGet(action, ['payload', 'selectedBank']);
  const wsResult = yield call(
    createAccount,
    accountNo,
    name,
    email,
    selectedBank
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    yield put(result(CREATE, { code: Result.SUCCESS }));
  } else if (safeDeepGet(wsResult, 'code') == 200) {
    yield put(result(CREATE, { code: Result.SUCCESS }));
  } else {
    yield put(result(CREATE, { code: Result.FAILED }));
  }
}

function* read(action) {
  yield put(loading(READ));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.product.read, sku);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function updateAccount(id, accountNo, name, email, selectedBank) {
  const body = {
    account_id: id,
    account_name: name,
    account_no: accountNo,
    account_bank: selectedBank,
    account_email: email,
  };
  const btoaBody = Buffer.from(
    decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
  ).toString('base64');
  const bodyParam = {
    data: btoaBody,
  };
  var result = JPOST({
    url:
      'https://serverless-api.iotera.io/api/' +
      APPLICATION_ID +
      '/account-update',
    body: {
      data: btoaBody,
    },
  });
  return result;
}

function* update(action) {
  yield put(loading(UPDATE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const accountNo = safeDeepGet(action, ['payload', 'rekening']);
  const email = safeDeepGet(action, ['payload', 'email']);
  const name = safeDeepGet(action, ['payload', 'name']);
  const selectedBank = safeDeepGet(action, ['payload', 'bank']);
  const wsResult = yield call(
    updateAccount,
    id,
    accountNo,
    name,
    email,
    selectedBank
  );
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS) {
    yield put(result(UPDATE, { code: Result.SUCCESS }));
  } else if (code == 200) {
    yield put(result(UPDATE, { code: Result.SUCCESS }));
  } else {
    yield put(result(UPDATE, { code: Result.FAILED }));
  }
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['img_url'] = imageUrl;
  }

  const wsResult = yield call(
    firebaseInstance.product.updateStock,
    sku,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(STOCK_UPDATE, { code }));
}

function deleteAccount(account_id) {
  var result = JPOST({
    url:
      'https://serverless-api.iotera.io/api/' +
      APPLICATION_ID +
      '/account-remove',
    queryParams: {
      account_id: btoa(account_id),
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}
function* delete_(payload) {
  yield put(loading(DELETE));
  const account_id = safeDeepGet(payload, ['payload', 'account_id'], '');
  const wsResult = yield call(deleteAccount, account_id);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* payout_validate(payload) {
  yield put(loading(PAYOUT_VALIDATE));
  const data = safeDeepGet(payload, 'payload', {});
  safeDeepSet(data, 'application_id', APPLICATION_ID);
  safeDeepSet(data, 'multitenancy', MULTITENANCY);
  const wsResult = yield call(validate_payout, data);
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR);
  const msg = safeDeepGet(wsResult, ['body', 'body', 'message'], '');
  if (code == Result.HTTP.OK) {
    yield put(result(PAYOUT_VALIDATE, { code: Result.SUCCESS, msg }));
    return;
  } else {
    yield put(result(PAYOUT_VALIDATE, { code: Result.FAILED, msg }));
  }
}
function* payout_cancel(payload) {
  yield put(loading(PAYOUT_CANCEL));
  const data = safeDeepGet(payload, 'payload', {});
  safeDeepSet(data, 'application_id', APPLICATION_ID);
  safeDeepSet(data, 'multitenancy', MULTITENANCY);
  const wsResult = yield call(cancel_payout, data);
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR);
  const msg = safeDeepGet(wsResult, ['body', 'body', 'message'], '');
  if (code == Result.HTTP.OK) {
    yield put(result(PAYOUT_CANCEL, { code: Result.SUCCESS, msg }));
    return;
  } else {
    yield put(result(PAYOUT_CANCEL, { code: Result.FAILED, msg }));
  }
}

function* payout_request(payload) {
  yield put(loading(PAYOUT_REQUEST));
  const phoneNumber = localStorage.getItem('phoneNumber');
  const email = localStorage.getItem('email');
  let type = 'WhatsApp';

  const idPayout = safeDeepGet(payload, 'payload', '');
  let wsResult = yield call(getPayoutData, idPayout);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const body = safeDeepGet(wsResult, ['body', 'data'], {});
    safeDeepSet(body, 'payoutId', idPayout);
    safeDeepSet(body, 'application_id', APPLICATION_ID);
    safeDeepSet(body, 'multitenancy', MULTITENANCY);
    safeDeepSet(body, 'phone', phoneNumber);
    safeDeepSet(body, 'email', email);
    wsResult = yield call(create_payout, body);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const body = safeDeepGet(wsResult, ['body', 'server'], {});
      const notes = safeDeepGet(wsResult, ['body', 'notes'], '-');
      const server_code = safeDeepGet(body, 'server_code', '');
      yield put(
        result(PAYOUT_REQUEST, { code: Result.SUCCESS, server_code, notes })
      );
    } else {
      yield put(result(PAYOUT_REQUEST, { code: Result.FAILED }));
    }
  } else {
    yield put(result(PAYOUT_REQUEST, { code: Result.FAILED }));
  }

  // TODO JIKA SEND OTP NOMER HP GAGAL MAKA GUNAKAN EMAIL
}

function* payoutSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(PAYOUT_LIST, payoutlist);
  yield takeEvery(PAYOUT_VALIDATE, payout_validate);
  yield takeEvery(PAYOUT_REQUEST, payout_request);
  yield takeEvery(ACCOUNT_LIST, accountList);
  yield takeEvery(PAYOUT_CREATE, payout_create);
  yield takeEvery(PAYOUT_CANCEL, payout_cancel);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(STOCK_UPDATE, stockUpdate);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(DETAIL_ACCOUNT, detailAccount);
  yield takeEvery(GET_BALANCE, get_Balance);
  yield takeEvery(POST_USER_DATA, postData);
}

export default payoutSaga;
