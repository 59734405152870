/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
// eslint-disable-next-line no-unused-vars
import {
  call,
  put,
  takeEvery,
  all,
  fork,
  delay,
  select,
} from 'redux-saga/effects';
import {
  dummyData,
  getDownloadData,
  device_list as listDevice,
  searchData,
} from 'Apps/device';
const momenttz = require('moment-timezone');
const moment = require('moment');
import axios from 'axios';
// Product state
import {
  LIST,
  LIST_BUSINESS_POINTS,
  READ,
  REFUND,
  SUMMARY,
  SNAPSHOT,
  GET_SALES_ANALYTICS,
  UPDATE_REFUND,
  REFUNDED,
  REFUND_LIST,
  DETAIL,
  SNAPSHOT_DAILY,
  SNAPSHOT_MONTHLY,
  GET_REPORT_GROUP,
  NEXT_LIST,
  DOWNLOAD_DATA,
  SEARCH_DATA,
  UPDATE_DOWNLOAD_PROGRESS,
} from './actionTypes';

import { loading, result, updateDownloadProgress } from './actions';
import { getInstance } from '../../firebase/helper';
import {
  safeDeepGet,
  safeDeepSet,
  forceDeepSet,
  safeGet,
  safeDeepGetWithType,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import {
  APPLICATION_ID,
  FIREBASE_URL,
  PROJECT_HANDLE,
  SHARING_PROFIT_PERCENTAGE,
} from 'Apps';
import { useBootstrapMinBreakpoint } from 'react-bootstrap/esm/ThemeProvider';
import { refundTrx } from 'Apps/device';

const MULTITENANCY = JSON.parse(
  localStorage.getItem('multitenancy') || 'false'
);

function* get_report_group(action) {
  const payload = safeDeepGet(action, 'payload', {});
  yield put(loading(GET_REPORT_GROUP));
  const firebaseInstance = getInstance();
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  let tag = safeDeepGet(payload, 'tags', '-');
  if (tag == '') {
    tag = null;
  }
  safeDeepSet(payload, 'tags', tag);
  safeDeepSet(payload, 'allBp', allBp);
  if (tag != '-') {
    wsResult = yield call(
      firebaseInstance.businessPoint.list_tags,
      tag,
      allBp,
      arr_bp
    );
    const arr_list_tags = safeDeepGet(wsResult, 'body', arr_bp);
    safeDeepSet(payload, 'arr_bp', arr_list_tags);
  } else {
    safeDeepSet(payload, 'arr_bp', arr_bp);
  }
  safeDeepSet(payload, 'allBp', allBp);
  safeDeepSet(payload, 'multitenancy', MULTITENANCY);
  safeDeepSet(payload, 'application_id', APPLICATION_ID);
  safeDeepSet(payload, 'PROJECT_HANDLE', PROJECT_HANDLE);

  const quarter = safeDeepGet(payload, ['quarter', 'value'], '-');
  const businessPoints = safeDeepGet(payload, 'businessPoints', '');
  const selectedMonthYear = safeDeepGet(payload, 'selectedMonthYear', '');
  let endPoint = '/getDownloadQuarter';

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    try {
      const response = yield call(() =>
        axios.post(FIREBASE_URL + endPoint, payload, {
          responseType: 'blob',
          onDownloadProgress: function (progressEvent) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // Gunakan Redux Action melalui closure
            window.dispatchDownloadProgressQ(progress);
          },
        })
      );

      const buffer = response.data;
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      var report_filename = quarter + '.xlsx';
      var tenant = PROJECT_HANDLE;
      if (PROJECT_HANDLE === 'Fuji') {
        tenant = 'VendkiosK';
      } else if (APPLICATION_ID == '1000000159') {
        tenant = 'Milano';
      }
      if (quarter != undefined) {
        report_filename =
          tenant +
          '_' +
          quarter +
          '_Report_' +
          businessPoints +
          '_' +
          selectedMonthYear +
          '.xlsx';
      } else {
        report_filename =
          tenant +
          '_Now' +
          '_Report_' +
          businessPoints +
          '_' +
          selectedMonthYear +
          '.xlsx';
      }

      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = report_filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);

      yield put(result(GET_REPORT_GROUP, { code: Result.SUCCESS }));
      return;
    } catch (error) {
      yield put(
        result(GET_REPORT_GROUP, { code: Result.SUCCESS, error: error })
      );
      return;
    }
  }
}

function initializeEarnings() {
  const earnings = {};
  for (let i = 1; i <= 31; i++) {
    earnings[i] = { x: i.toString().padStart(2, '0'), y: 0 };
  }
  return earnings;
}
function initializeDataDownloads() {
  const earnings = {};
  for (let i = 1; i <= 31; i++) {
    earnings[i] = { x: i.toString().padStart(2, '0'), y: 0, settle: 0 };
  }
  return earnings;
}
function getNettAmount(dataBp, paymentMethod) {
  const methodKey = paymentMethod
    .replace('QRIS-MIDTRANS', 'QRIS')
    .replace('QRIS-BANKNOBU', 'NOBU')
    .replace('QRIS-MIDTRANS-PARTNER', 'PARTNER');

  if (['CASH', 'RFID', 'QRIS', 'NOBU', 'PARTNER'].includes(methodKey)) {
    return {
      nettAmount: safeDeepGet(
        dataBp,
        ['detail_method', methodKey, 'total_sales'],
        0
      ),
      hpp: safeDeepGet(dataBp, ['detail_method', methodKey, 'total_hpp'], 0),
      settlement: safeDeepGet(
        dataBp,
        ['detail_method', methodKey, 'total_settle'],
        0
      ),
      landlord: safeDeepGet(
        dataBp,
        ['detail_method', methodKey, 'total_landlord'],
        0
      ),
    };
  } else {
    return {
      nettAmount: safeDeepGet(dataBp, ['total', 'total_sales'], 0),
      hpp: safeDeepGet(dataBp, ['total', 'total_hpp'], 0),
      settlement: safeDeepGet(dataBp, ['total', 'total_settle'], 0),
      landlord: safeDeepGet(dataBp, ['total', 'total_landlord'], 0),
    };
  }
}

function* get_snapshot_monthly(payload) {
  yield put(loading(SNAPSHOT_MONTHLY));
  const startTs = new Date().getTime();
  const logMap = {};
  const trxMap = {};
  const firebaseInstance = getInstance();
  // const tag = 'aj9';
  const tag = localStorage.getItem('tag') ?? JSON.parse(null);
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  const businessPoint = safeDeepGet(payload, ['payload', 'businessPoints'], '');
  const paymentMethod = safeDeepGet(payload, ['payload', 'bank'], '');
  const date = safeDeepGet(
    payload,
    ['payload', 'date'],
    safeDeepGet(payload, ['payload', 'dateRange'], [])
  );
  const startDate = date[0];
  const endDate = date[1];
  const month = safeDeepGet(
    payload,
    ['payload', 'month'],
    new Date().getMonth()
  );
  const year = safeDeepGet(
    payload,
    ['payload', 'year'],
    new Date().getFullYear()
  );
  const startDateLast = startDate.getDate();
  const endDateLast = endDate.getDate();
  const start = new Date(year, month, startDateLast);
  const end = new Date(year, month, endDateLast, 23, 59, 59);
  const payloadNew = {
    type: 'TRANSACTION_SNAPSHOT_MONTHLY',
    businessPoints: businessPoint,
    month: month,
    year: year,
    date: [start, end],
  };

  let wsResult = yield call(firebaseInstance.snapshotDaily.list, payloadNew);
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR);
  if (code === Result.SUCCESS) {
    const dailyBody = safeDeepGet(wsResult, 'body');
    let amount = 0;
    let hpp = 0;
    let mdrAmount = 0;
    let settlement = 0;
    let landlord = 0;
    let amountRefund = 0;
    let product = {};
    let bpMap = {};
    let earning = initializeEarnings();
    let trxBody = {};

    if (businessPoint !== 'all') {
      for (const item in dailyBody) {
        let bpJson = safeDeepGet(dailyBody, [item, 'business_point'], {});
        if (typeof bpJson === 'string') bpJson = JSON.parse(bpJson);
        if (!bpJson || !bpJson[businessPoint]) continue;

        const id = Number(item.slice(-2));
        const dataBp = safeDeepGet(bpJson, [businessPoint], {});

        const {
          nettAmount,
          hpp: hppVal,
          settlement: settlementVal,
          landlord: landlordVal,
        } = getNettAmount(dataBp, paymentMethod);

        const mdr = 0.007 * nettAmount;
        amount += nettAmount;
        hpp += hppVal;
        settlement += settlementVal;
        landlord += landlordVal;
        mdrAmount += mdr;
        amountRefund += safeDeepGet(dataBp, ['total', 'total_refund'], 0);

        // Update earning
        earning[id].y += nettAmount;

        product[item] = safeDeepGet(dataBp, ['detail_product'], {});
        bpMap[item] = bpJson;
      }
    } else if (tag !== null) {
      for (const item in dailyBody) {
        let tagsJson = safeDeepGet(dailyBody, [item, 'tags'], {});
        if (typeof tagsJson === 'string') tagsJson = JSON.parse(tagsJson);
        if (!tagsJson || !tagsJson[tag]) continue;

        const id = Number(item.slice(-2));
        const dataBp = safeDeepGet(tagsJson, [tag], {});

        const {
          nettAmount,
          hpp: hppVal,
          settlement: settlementVal,
          landlord: landlordVal,
        } = getNettAmount(dataBp, paymentMethod);

        const mdr = 0.007 * nettAmount;
        amount += nettAmount;
        hpp += hppVal;
        settlement += settlementVal;
        landlord += landlordVal;
        mdrAmount += mdr;
        amountRefund += safeDeepGet(dataBp, ['total', 'total_refund'], 0);

        // Update earning
        earning[id].y += nettAmount;

        product[item] = safeDeepGet(dataBp, ['detail_product'], {});
        bpMap[item] = safeDeepGet(tagsJson, 'business_point', {});
      }
    } else {
      earning = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).earning;
      bpMap = calculateEarnings(dailyBody, arr_bp, allBp, paymentMethod).bpMap;
      amount = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).amount;
      amountRefund = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).amountRefund;
      hpp = calculateEarnings(dailyBody, arr_bp, allBp, paymentMethod).hpp;
      landlord = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).landlord;
      mdrAmount = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).mdrAmount;
      product = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).product;
      settlement = calculateEarnings(
        dailyBody,
        arr_bp,
        allBp,
        paymentMethod
      ).settlement;
    }

    const timezone = 'Asia/Jakarta';
    moment.tz.setDefault(timezone);
    const today = new Date();
    const now = momenttz.tz(today, timezone);
    const Date_1 = now.clone().subtract(0, 'days').startOf('days');
    const Date_2 = now.clone().subtract(0, 'days').endOf('days');
    const Date_3 = now.clone().subtract(0, 'month').startOf('month');
    const Date_4 = now.clone().subtract(0, 'month').endOf('month');
    const Date_5 = now.clone().subtract(2, 'days').startOf('days');
    const todayStart = Date_3.valueOf();
    const todayEnd = Date_4.valueOf();
    const todayStartDate = new Date(todayStart);
    const todayEndDate = new Date(todayEnd);
    const twoDaysBefore = new Date(Date_5);
    const MonthBefore = now.clone().subtract(1, 'month').endOf('month');
    const beforeMonth = momenttz.tz(MonthBefore, timezone);
    const date_30 = new Date(
      beforeMonth.clone().subtract(1, 'days').startOf('days').valueOf()
    );
    const date_31 = new Date(
      beforeMonth.clone().subtract(0, 'days').endOf('days').valueOf()
    );
    let payload_trx = {
      dateRange: [twoDaysBefore, today],
      businessPoints: businessPoint,
      bank: paymentMethod,
      tags: localStorage.getItem('tag') ?? JSON.parse(null),
      arr_bp,
      allBp,
    };

    const isSameMonth = today.getMonth() === date[0].getMonth();
    const isPrevMonth = today.getMonth() - 1 === date[0].getMonth();
    const isFirstOrSecond = today.getDate() === 1 || today.getDate() === 2;

    if (isSameMonth) {
      payload_trx = {
        dateRange: [twoDaysBefore, today],
        businessPoints: businessPoint,
        bank: paymentMethod,
      };

      if (isFirstOrSecond) {
        payload_trx.dateRange = [todayStartDate, todayEndDate];
      }
    } else if (isPrevMonth && isFirstOrSecond) {
      payload_trx = {
        dateRange: [date_30, date_31],
        businessPoints: businessPoint,
        bank: paymentMethod,
      };
    }

    if (today.getMonth() == date[0].getMonth()) {
      trxMap['payloadTrx'] = payload_trx;
      wsResult = yield call(firebaseInstance.transaction.list, payload_trx);
      trxBody = safeDeepGet(wsResult, 'body', {});
      for (const trx in trxBody) {
        const device_id = safeDeepGet(
          trxBody,
          [trx, 'product', 'device_id'],
          ''
        );
        const timeStamp = safeDeepGet(trxBody, [trx, 'time', 'timestamp'], {});
        const date = new Date(timeStamp).getDate();
        if (trxMap[date] === undefined) {
          trxMap[date] = { amount_trx: 1 };
        } else {
          trxMap[date]['amount_trx'] += 1;
        }
        if (
          safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
          'settlement'
        ) {
          const nett = safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
          const mdr = safeDeepGet(
            trxBody,
            [trx, 'payment', 'fee', 'mdr_qris'],
            0
          );
          const amount_landlord = safeDeepGet(
            trxBody,
            [trx, 'payment', 'fee', 'landlord_sharing_revenue'],
            0
          );
          if (allBp == 'false') {
            if (arr_bp.includes(device_id)) {
              mdrAmount += mdr;
              earning[date].y += nett;
              amount += nett;
              landlord += amount_landlord;
              settlement += 1;
              hpp += safeDeepGet(trxBody, [trx, 'payment', 'hpp'], 0);
            }
          } else {
            mdrAmount += mdr;
            earning[date].y += nett;
            amount += nett;
            landlord += amount_landlord;
            settlement += 1;
            hpp += safeDeepGet(trxBody, [trx, 'payment', 'hpp'], 0);
          }
        } else if (
          safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
          'refunded'
        ) {
          if (allBp == 'false') {
            if (arr_bp.includes(device_id)) {
              amountRefund += safeDeepGet(
                trxBody,
                [trx, 'payment', 'amount'],
                0
              );
            }
          } else {
            amountRefund += safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
          }
        }
      }
    }

    const businessPointMap = {};
    wsResult = yield call(firebaseInstance.businessPoint.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const businessPointBody = wsResult.body;
      for (const id in businessPointBody) {
        const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
        businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
      }
    }
    wsResult = yield call(listDevice);
    const devices = safeDeepGet(wsResult, ['body', 'devices']);
    const bp = {};
    for (const item in bpMap) {
      const bpItem = bpMap[item];
      const keys = Object.keys(bpItem);
      if (keys.length > 0) {
        for (const key in keys) {
          const device_id = keys[key];
          if (businessPoint != 'all') {
            if (device_id == businessPoint) {
              forceDeepSet(
                bp,
                [device_id, item],
                safeDeepGet(bpItem, device_id, {}),
                {}
              );
            }
          } else {
            if (allBp == 'false') {
              if (arr_bp.includes(device_id)) {
                forceDeepSet(
                  bp,
                  [device_id, item],
                  safeDeepGet(bpItem, device_id, {}),
                  {}
                );
              }
            } else {
              forceDeepSet(
                bp,
                [device_id, item],
                safeDeepGet(bpItem, device_id, {}),
                {}
              );
            }
          }
        }
      }
    }
    const downloadList = [];
    for (const item in businessPointMap) {
      const bpDateMap = {};
      const data = initializeDataDownloads();
      const dataBp = bp[item];
      if (dataBp != undefined) {
        for (const date in dataBp) {
          const dateSlice = date.slice(-2);
          const id = Number(dateSlice);

          if (paymentMethod == 'CASH') {
            const amountCash = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'CASH', 'total_sales'],
              0
            );
            //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
            const nettAmount = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'CASH', 'total_sales'],
              0
            );
            forceDeepSet(
              data,
              [id.toString(), 'y'],
              (data[id.toString()].y += nettAmount)
            );
            forceDeepSet(
              data,
              [id.toString(), 'settle'],
              (data[id.toString()].settle += safeDeepGet(
                dataBp,
                [date, 'detail_method', 'CASH', 'total_settle'],
                0
              ))
            );
          } else if (paymentMethod == 'RFID') {
            const amountRFID = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'RFID', 'total_sales'],
              0
            );
            //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
            const nettAmount = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'RFID', 'total_sales'],
              0
            );
            forceDeepSet(
              data,
              [id.toString(), 'y'],
              (data[id.toString()].y += nettAmount)
            );
            forceDeepSet(
              data,
              [id.toString(), 'settle'],
              (data[id.toString()].settle += safeDeepGet(
                dataBp,
                [date, 'detail_method', 'RFID', 'total_settle'],
                0
              ))
            );
          } else if (paymentMethod == 'QRIS-MIDTRANS') {
            const amountQRIS = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'QRIS', 'total_sales'],
              0
            );
            //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
            const nettAmount = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'QRIS', 'total_sales'],
              0
            );
            forceDeepSet(
              data,
              [id.toString(), 'y'],
              (data[id.toString()].y += nettAmount)
            );
            forceDeepSet(
              data,
              [id.toString(), 'settle'],
              (data[id.toString()].settle += safeDeepGet(
                dataBp,
                [date, 'detail_method', 'QRIS', 'total_settle'],
                0
              ))
            );
          } else if (paymentMethod == 'QRIS-BANKNOBU') {
            const amountQRIS = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'NOBU', 'total_sales'],
              0
            );
            //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
            const nettAmount = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'NOBU', 'total_sales'],
              0
            );
            forceDeepSet(
              data,
              [id.toString(), 'y'],
              (data[id.toString()].y += nettAmount)
            );
            forceDeepSet(
              data,
              [id.toString(), 'settle'],
              (data[id.toString()].settle += safeDeepGet(
                dataBp,
                [date, 'detail_method', 'NOBU', 'total_settle'],
                0
              ))
            );
          } else if (paymentMethod == 'QRIS-MIDTRANS-PARTNER') {
            const amountQRIS = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'PARTNER', 'total_sales'],
              0
            );
            //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
            const nettAmount = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'PARTNER', 'total_sales'],
              0
            );
            forceDeepSet(
              data,
              [id.toString(), 'y'],
              (data[id.toString()].y += nettAmount)
            );
            forceDeepSet(
              data,
              [id.toString(), 'settle'],
              (data[id.toString()].settle += safeDeepGet(
                dataBp,
                [date, 'detail_method', 'PARTNER', 'total_settle'],
                0
              ))
            );
          } else {
            const amountQris = safeDeepGet(
              dataBp,
              [date, 'detail_method', 'QRIS', 'total_sales'],
              0
            );
            //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
            const nettAmount = safeDeepGet(
              dataBp,
              [date, 'total', 'total_sales'],
              0
            );
            // console.log(id.toString(), nettAmount);
            // console.log(id.toString(), data[id.toString()].y += nettAmount);

            forceDeepSet(
              data,
              [id.toString(), 'y'],
              (data[id.toString()].y += nettAmount)
            );

            forceDeepSet(
              data,
              [id.toString(), 'settle'],
              (data[id.toString()].settle += safeDeepGet(
                dataBp,
                [date, 'total', 'total_settle'],
                0
              ))
            );
          }
        }
      }
      if (today.getMonth() === todayStartDate.getMonth()) {
        for (const trx in trxBody) {
          const timeStamp = safeDeepGet(
            trxBody,
            [trx, 'time', 'timestamp'],
            {}
          );
          const device_id = safeDeepGet(
            trxBody,
            [trx, 'product', 'device_id'],
            ''
          );
          if (allBp == 'false') {
            if (arr_bp.includes(device_id)) {
              if (device_id == item) {
                const date = new Date(timeStamp).getDate();
                if (
                  safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
                  'settlement'
                ) {
                  data[date].y += safeDeepGet(
                    trxBody,
                    [trx, 'payment', 'amount'],
                    0
                  );
                  data[date].settle += 1;
                }
              }
            }
          } else {
            if (device_id == item) {
              const date = new Date(timeStamp).getDate();
              if (
                safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
                'settlement'
              ) {
                data[date].y += safeDeepGet(
                  trxBody,
                  [trx, 'payment', 'amount'],
                  0
                );
                data[date].settle += 1;
              }
            }
          }
        }
      } else {
        if (today.getDate() == 1) {
          for (const trx in trxBody) {
            const timeStamp = safeDeepGet(
              trxBody,
              [trx, 'time', 'timestamp'],
              {}
            );
            const device_id = safeDeepGet(
              trxBody,
              [trx, 'product', 'device_id'],
              ''
            );
            if (arr_bp.includes(device_id)) {
              if (device_id == item) {
                const date = new Date(timeStamp).getDate();
                if (
                  safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
                  'settlement'
                ) {
                  data[date].y += safeDeepGet(
                    trxBody,
                    [trx, 'payment', 'nett'],
                    0
                  );
                  data[date].settle += 1;
                }
              }
            }
          }
        }
      }

      const arrData = [];
      for (const i in data) {
        arrData.push(data[i]);
      }
      forceDeepSet(bpDateMap, 'data', arrData);
      forceDeepSet(bpDateMap, 'deviceId', item);
      const bpName = safeDeepGet(businessPointMap, [item, 'name'], '-');
      const serial_number = safeDeepGet(devices, [item, 'serial_number'], '-');
      forceDeepSet(bpDateMap, 'name', bpName);
      safeDeepSet(bpDateMap, 'serial_number', serial_number);
      if (allBp == 'false') {
        if (businessPoint != 'all') {
          if (businessPoint == item) {
            downloadList.push(bpDateMap);
          }
        } else {
          if (arr_bp.includes(item)) {
            downloadList.push(bpDateMap);
          }
        }
      } else {
        if (businessPoint != 'all') {
          if (businessPoint == item) {
            downloadList.push(bpDateMap);
          }
        } else {
          downloadList.push(bpDateMap);
        }
      }
    }
    if (downloadList.length < 1) {
      for (const item in businessPointMap) {
        const bpDateMap = {};
        const data = initializeDataDownloads();
        for (const trx in trxBody) {
          const timeStamp = safeDeepGet(
            trxBody,
            [trx, 'time', 'timestamp'],
            {}
          );
          const device_id = safeDeepGet(
            trxBody,
            [trx, 'product', 'device_id'],
            ''
          );
          if (device_id == item) {
            const date = new Date(timeStamp).getDate();
            if (
              safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
              'settlement'
            ) {
              data[date.toString()].y += safeDeepGet(
                trxBody,
                [trx, 'payment', 'nett'],
                0
              );
              data[date.toString()].settle += 1;
            }
          }
        }

        const arrData = [];
        for (const i in data) {
          arrData.push(data[i]);
        }
        forceDeepSet(bpDateMap, 'data', arrData);
        forceDeepSet(bpDateMap, 'deviceId', item);
        const bpName = safeDeepGet(businessPointMap, [item, 'name'], '-');
        const serial_number = safeDeepGet(
          devices,
          [item, 'serial_number'],
          '-'
        );
        forceDeepSet(bpDateMap, 'name', bpName);
        safeDeepSet(bpDateMap, 'serial_number', serial_number);
        downloadList.push(bpDateMap);
      }
    }
    const productMap = {};
    for (const item in product) {
      const data = product[item];
      const keys = Object.keys(data);
      if (keys.length > 0) {
        for (const key in keys) {
          const sku = keys[key];
          if (productMap[sku] === undefined) {
            productMap[sku] = { total_sales: 0, total_settle: 0 };
            productMap[sku].total_sales += safeDeepGet(
              data,
              [sku, 'total_sales'],
              0
            );
            productMap[sku].total_settle += safeDeepGet(
              data,
              [sku, 'total_settle'],
              0
            );
          } else {
            productMap[sku].total_sales += safeDeepGet(
              data,
              [sku, 'total_sales'],
              0
            );
            productMap[sku].total_settle += safeDeepGet(
              data,
              [sku, 'total_settle'],
              0
            );
          }
        }
      }
    }
    const bpSettleMap = {};
    if (today.getMonth() === date[0].getMonth()) {
      for (const trx in trxBody) {
        const skutrx = safeDeepGet(trxBody, [trx, 'product', 'sku'], '');
        const device_idtrx = safeDeepGet(
          trxBody,
          [trx, 'product', 'device_id'],
          ''
        );
        if (
          safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
          'settlement'
        ) {
          if (productMap[skutrx] === undefined) {
            productMap[skutrx] = { total_sales: 0, total_settle: 0 };
            productMap[skutrx].total_sales += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            productMap[skutrx].total_settle += 1;
          } else {
            productMap[skutrx].total_sales += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            productMap[skutrx].total_settle += 1;
          }
          if (bpSettleMap[device_idtrx] === undefined) {
            bpSettleMap[device_idtrx] = { total_sales: 0, total_settle: 0 };
            bpSettleMap[device_idtrx].total_sales += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            bpSettleMap[device_idtrx].total_settle += 1;
          } else {
            bpSettleMap[device_idtrx].total_sales += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            bpSettleMap[device_idtrx].total_settle += 1;
          }
        }
      }
    }
    var ret_sales_analytics = [];
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productBody = wsResult.body;
      for (var prop in productMap) {
        let productName = safeDeepGet(productBody, [prop, 'name'], prop);
        ret_sales_analytics.push({
          name: productName,
          value: productMap[prop].total_settle,
        });
      }
    }
    const data_sales = {};
    for (const item in bp) {
      const bpName = safeDeepGet(businessPointMap, [item, 'name'], '-');
      const dataBp = bp[item];
      for (const data in dataBp) {
        if (data_sales.hasOwnProperty(bpName)) {
          data_sales[bpName] =
            data_sales[bpName] + dataBp[data].total.total_sales;
        } else {
          data_sales[bpName] = dataBp[data].total.total_sales;
        }
      }
    }
    for (const item in bpSettleMap) {
      const bpName = safeDeepGet(businessPointMap, [item, 'name'], '-');
      if (data_sales.hasOwnProperty(bpName)) {
        data_sales[bpName] = data_sales[bpName] + bpSettleMap[item].total_sales;
      } else {
        data_sales[bpName] = bpSettleMap[item].total_sales;
      }
    }
    var bp_sales_analytics = [];
    for (const item in data_sales) {
      bp_sales_analytics.push({ name: item, value: data_sales[item] });
    }
    const sorting = bp_sales_analytics.sort(
      (a, b) => parseFloat(b.value) - parseFloat(a.value)
    );
    const slice = sorting.slice(0, 10);

    const startDate = date[0];
    const endDate = date[1];
    const month = safeDeepGet(payload, 'month', new Date().getMonth());
    const year = safeDeepGet(payload, 'year', new Date().getFullYear());
    const businessPoints = safeDeepGet(payload, 'businessPoints', '');
    const monthLast = startDate.getMonth() - 1;
    const startDateLast = startDate.getDate();
    const endDateLast = endDate.getDate();
    const startLast = new Date(year, monthLast, startDateLast);
    const endLast = new Date(year, monthLast, endDateLast, 23, 59, 59);
    const payload_prev = {
      type: 'TRANSACTION_SNAPSHOT_MONTHLY',
      businessPoints: businessPoints,
      month: month - 1,
      year: year,
      date: [startLast, endLast],
    };
    wsResult = yield call(firebaseInstance.snapshotDaily.list, payload_prev);
    const dailyLastBody = safeDeepGet(wsResult, 'body', []);
    let amountLast = 0;
    let hppLast = 0;
    let landlordLast = 0;
    let settlementLast = 0;
    let amountLastRefund = 0;
    if (businessPoint != 'all' || allBp == 'false') {
      if (allBp == 'false') {
        for (const item in dailyLastBody) {
          for (const bp in arr_bp) {
            const device_id = arr_bp[bp];
            let datas = safeDeepGet(dailyLastBody, [item, 'business_point']);
            if (typeof datas == 'string') {
              datas = JSON.parse(datas);
            }
            if (safeDeepGet(datas, [device_id], null) != null) {
              if (paymentMethod == 'CASH') {
                const nettAmount = safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'CASH', 'total_sales'],
                  0
                );
                //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
                amountLast += nettAmount;
                hppLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'CASH', 'total_hpp'],
                  0
                );
                settlementLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'CASH', 'total_settle'],
                  0
                );
                landlordLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'CASH', 'total_landlord'],
                  0
                );
              } else if (paymentMethod == 'RFID') {

                const nettAmount = safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'RFID', 'total_sales'],
                  0
                );

                amountLast += nettAmount;
                hppLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'RFID', 'total_hpp'],
                  0
                );
                settlementLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'RFID', 'total_settle'],
                  0
                );
                landlordLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'RFID', 'total_landlord'],
                  0
                );
              } else if (paymentMethod == 'QRIS-MIDTRANS') {

                const nettAmount = safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'QRIS', 'total_sales'],
                  0
                );
                //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
                amountLast += nettAmount;
                hppLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'QRIS', 'total_hpp'],
                  0
                );
                settlementLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'QRIS', 'total_settle'],
                  0
                );
                landlordLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'QRIS', 'total_landlord'],
                  0
                );
              } else if (paymentMethod == 'QRIS-BANKNOBU') {
                const nettAmount = safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'NOBU', 'total_sales'],
                  0
                );
                //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
                amountLast += nettAmount;
                hppLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'NOBU', 'total_hpp'],
                  0
                );
                settlementLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'NOBU', 'total_settle'],
                  0
                );
                landlordLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'NOBU', 'total_landlord'],
                  0
                );
              } else if (paymentMethod == 'QRIS-MIDTRANS-PARTNER') {
                const nettAmount = safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'PARTNER', 'total_sales'],
                  0
                );
                //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
                amountLast += nettAmount;
                hppLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'PARTNER', 'total_hpp'],
                  0
                );
                settlementLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'PARTNER', 'total_settle'],
                  0
                );
                landlordLast += safeDeepGet(
                  datas,
                  [device_id, 'detail_method', 'PARTNER', 'total_landlord'],
                  0
                );
              } else {

                const nettAmount = safeDeepGet(
                  datas,
                  [device_id, 'total', 'total_sales'],
                  0
                );
                amountLast += nettAmount;
                hppLast += safeDeepGet(
                  datas,
                  [device_id, 'total', 'total_hpp'],
                  0
                );
                settlementLast += safeDeepGet(
                  datas,
                  [device_id, 'total', 'total_settle'],
                  0
                );
                landlordLast += safeDeepGet(
                  datas,
                  [device_id, 'total', 'total_landlord'],
                  0
                );
              }
              amountLastRefund += safeDeepGet(
                datas,
                [device_id, 'total', 'total_refund'],
                0
              );
            }
          }
        }
      } else {
        for (const item in dailyLastBody) {
          let datas = safeDeepGet(dailyLastBody, [item, 'business_point']);
          if (typeof datas == 'string') {
            datas = JSON.parse(datas);
          }
          if (safeDeepGet(datas, [businessPoint], null) != null) {
            if (paymentMethod == 'CASH') {

              const nettAmount = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'CASH', 'total_sales'],
                0
              );
              //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
              amountLast += nettAmount;
              hppLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'CASH', 'total_hpp'],
                0
              );
              settlementLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'CASH', 'total_settle'],
                0
              );
              landlordLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'CASH', 'total_landlord'],
                0
              );
            } else if (paymentMethod == 'RFID') {

              const nettAmount = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'RFID', 'total_sales'],
                0
              );

              amountLast += nettAmount;
              hppLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'RFID', 'total_hpp'],
                0
              );
              settlementLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'RFID', 'total_settle'],
                0
              );
              landlordLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'RFID', 'total_landlord'],
                0
              );
            } else if (paymentMethod == 'QRIS-MIDTRANS') {
              const amountQris = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'QRIS', 'total_sales'],
                0
              );

              const nettAmount = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'QRIS', 'total_sales'],
                0
              );
              //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
              amountLast += nettAmount;
              hppLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'QRIS', 'total_hpp'],
                0
              );
              settlementLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'QRIS', 'total_settle'],
                0
              );
              landlordLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'QRIS', 'total_landlord'],
                0
              );
            } else if (paymentMethod == 'QRIS-BANKNOBU') {
              const amountQris = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'NOBU', 'total_sales'],
                0
              );

              const nettAmount = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'NOBU', 'total_sales'],
                0
              );
              //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
              amountLast += nettAmount;
              hppLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'NOBU', 'total_hpp'],
                0
              );
              settlementLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'NOBU', 'total_settle'],
                0
              );
              landlordLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'NOBU', 'total_landlord'],
                0
              );
            } else if (paymentMethod == 'QRIS-MIDTRANS-PARTNER') {
              const amountQris = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'PARTNER', 'total_sales'],
                0
              );

              const nettAmount = safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'PARTNER', 'total_sales'],
                0
              );
              //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
              amountLast += nettAmount;
              hppLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'PARTNER', 'total_hpp'],
                0
              );
              settlementLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'PARTNER', 'total_settle'],
                0
              );
              landlordLast += safeDeepGet(
                datas,
                [businessPoint, 'detail_method', 'PARTNER', 'total_landlord'],
                0
              );
            } else {
              //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
              const nettAmount = safeDeepGet(
                datas,
                [businessPoint, 'total', 'total_sales'],
                0
              );
              amountLast += nettAmount;
              hppLast += safeDeepGet(
                datas,
                [businessPoint, 'total', 'total_hpp'],
                0
              );
              settlementLast += safeDeepGet(
                datas,
                [businessPoint, 'total', 'total_settle'],
                0
              );
              landlordLast += safeDeepGet(
                datas,
                [businessPoint, 'total', 'total_landlord'],
                0
              );
            }
            amountLastRefund += safeDeepGet(
              datas,
              [businessPoint, 'total', 'total_refund'],
              0
            );
          }
        }
      }
    } else {
      for (const item in dailyLastBody) {
        if (paymentMethod == 'CASH') {
          const nettAmount = safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'CASH', 'total_sales'],
            0
          );
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID

          amountLast += nettAmount;
          hppLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'CASH', 'total_hpp'],
            0
          );
        } else if (paymentMethod == 'RFID') {
          const nettAmount = safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'RFID', 'total_sales'],
            0
          );
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID

          amountLast += nettAmount;
          hppLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'RFID', 'total_hpp'],
            0
          );
          landlordLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'RFID', 'total_landlord'],
            0
          );
        } else if (paymentMethod == 'QRIS-MIDTRANS') {
          const nettAmount = safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'QRIS', 'total_sales'],
            0
          );
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
          amountLast += nettAmount;
          hppLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'QRIS', 'total_hpp'],
            0
          );
          landlordLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'QRIS', 'total_landlord'],
            0
          );
        } else if (paymentMethod == 'QRIS-BANKNOBU') {
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
          const nettAmount = safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'NOBU', 'total_sales'],
            0
          );
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
          amountLast += nettAmount;
          hppLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'NOBU', 'total_hpp'],
            0
          );
          landlordLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'NOBU', 'total_landlord'],
            0
          );
        } else if (paymentMethod == 'QRIS-MIDTRANS-PARTNER') {
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
          const nettAmount = safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'PARTNER', 'total_sales'],
            0
          );
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
          amountLast += nettAmount;
          hppLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'PARTNER', 'total_hpp'],
            0
          );
          landlordLast += safeDeepGet(
            dailyLastBody,
            [item, 'detail_method', 'PARTNER', 'total_landlord'],
            0
          );
        } else {
          //TODO Ganti Sesuai Dengan Sharing Provit berdasarkan Applikasi ID
          const nettAmount = safeDeepGet(
            dailyLastBody,
            [item, 'total', 'total_sales'],
            0
          );

          amountLast += nettAmount;
          hppLast += safeDeepGet(
            dailyLastBody,
            [item, 'total', 'total_hpp'],
            0
          );
          landlordLast += safeDeepGet(
            dailyLastBody,
            [item, 'total', 'total_landlord'],
            0
          );
        }

        amountLastRefund += safeDeepGet(
          dailyLastBody,
          [item, 'total', 'total_refund'],
          0
        );
      }
    }

    if (businessPoint == 'all') {
      for (const i in businessPointMap) {
        if (allBp == 'false') {
          if (arr_bp.includes(i)) {
            const data = businessPointMap[i];
            const type = safeDeepGet(data, ['fee', 'type'], null);
            if (type == 'fixed') {
              const amount = safeDeepGet(data, ['fee', 'share_amount'], 0);
              landlord += amount;
            }
          }
        } else {
          const data = businessPointMap[i];
          const type = safeDeepGet(data, ['fee', 'type'], null);
          if (type == 'fixed') {
            const amount = safeDeepGet(data, ['fee', 'share_amount'], 0);
            landlord += amount;
          }
        }
      }
    } else {
      const data = businessPointMap[businessPoint];
      const type = safeDeepGet(data, ['fee', 'type'], null);
      if (type == 'fixed') {
        const amount = safeDeepGet(data, ['fee', 'share_amount'], 0);
        landlord += amount;
      }
    }

    let trx_current = amount;
    let trx_mdr = mdrAmount;
    let trx_prev = amountLast;

    let trx_change = calc_change(trx_current, trx_prev);
    let hpp_current = hpp;
    let hpp_prev = hppLast;
    let hpp_change = calc_change(hpp_current, hpp_prev);

    let refund_current = amountRefund;
    let refund_prev = amountLastRefund;
    let refund_change = calc_change(refund_current, refund_prev);

    let settlement_current = settlement;
    let settlement_prev = settlementLast;
    let settlement_change = calc_change(settlement_current, settlement_prev);

    let landlord_current = landlord;
    let landlord_prev = landlordLast;
    let landlord_change = calc_change(landlord_current, landlord_prev);

    let temp_change = 0.0;
    if (amountLast == 0 && amount > 0) {
      temp_change = 100.0;
    } else if (amountLast == 0 && amount == 0) {
      temp_change = 0.0;
    } else {
      temp_change = (amount / amountLast - 1.0) * 100.0;
    }
    const chart_data = [];
    for (const i in earning) {
      const x = safeDeepGet(earning[i], 'x', '0');
      const y = safeDeepGet(earning[i], 'y', 1);
      chart_data.push({ x: x, y: y.toFixed(2) });
      // safeDel(earning, i);
    }
    const nett_settlement = {
      card_values: {
        card_mdr_current: trx_mdr,
        card_sum_current: amount,
        card_sum_prev: amountLast,
        card_change: temp_change,
        card_hpp: hpp,
      },
      chart_data: chart_data,
    };
    amount = 0;
    settlement = 0;
    landlord = 0;
    amountRefund = 0;
    amountLast = 0;
    hppLast = 0;

    settlementLast = 0;
    amountLastRefund = 0;
    landlordLast = 0;
    const ret_summary = {
      landlord_current: landlord_current,
      landlord_prev: landlord_prev,
      landlord_change: landlord_change,
      trx_current: trx_current,
      trx_prev: trx_prev,
      trx_change: trx_change,
      refund_current: refund_current,
      refund_prev: refund_prev,
      refund_change: refund_change,
      settlement_current: settlement_current,
      settlement_prev: settlement_prev,
      settlement_change: settlement_change,
      hpp_current: hpp_current,
      hpp_prev: hpp_prev,
      hpp_change: hpp_change,
    };

    const endTs = new Date().getTime();

    logMap['functions'] = 'Report get_snapshot_monthly';

    logMap['startTs'] = startTs;
    logMap['startTime'] = new Date(startTs + 7 * 60 * 60 * 1000);

    logMap['endTs'] = endTs;
    logMap['endTime'] = new Date(endTs + 7 * 60 * 60 * 1000);

    logMap['trxData'] = trxMap;

    const param = {
      url: 'https://api-serverless.iotera.io/' + APPLICATION_ID + '/log-trx',
      body: logMap,
    };
    JPOST(param);

    yield put(
      result(SNAPSHOT_MONTHLY, {
        code: Result.SUCCESS,
        body: ret_summary,
        sales_analytics: ret_sales_analytics,
        nett_settlement: nett_settlement,
        download_data: downloadList,
        bp_sales_analytics: slice,
        // bp_sales_analytics: bp_sales_analytics,
      })
    );
  } else {
    yield put(result(SNAPSHOT_MONTHLY, { code: Result.FAILED }));
    amount = 0;
    settlement = 0;
    amountRefund = 0;
    amountLast = 0;
    settlementLast = 0;
    amountLastRefund = 0;
  }
}

function* list_business_points(payload) {
  yield put(loading(LIST_BUSINESS_POINTS));
  const firebaseInstance = getInstance();
  const data = safeDeepGet(payload, ['payload']);
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list, data);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    const bpFilterList = [{ label: 'All points', value: 'all' }];
    for (const deviceId in businessPointMap) {
      let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
      if (allBp == 'true') {
        bpFilterList.push({ label: bp_name, value: deviceId });
      } else if (allBp == 'false') {
        if (arr_bp.includes(deviceId)) {
          bpFilterList.push({ label: bp_name, value: deviceId });
        }
      }
      // bpFilterList.push({ label: bp_name, value: deviceId });
    }

    yield put(
      result(LIST_BUSINESS_POINTS, { code: Result.SUCCESS, body: bpFilterList })
    );
    return;
  }
  yield put(result(LIST_BUSINESS_POINTS, { result: { code: Result.FAILED } }));
}

function* list(payload) {
  yield put(loading(LIST));
  const startTs = new Date().getTime();
  const logMap = {};
  const firebaseInstance = getInstance();
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let tag = safeDeepGet(payload, 'tags', null);
  if (tag == null || tag == 'null') {
    tag = localStorage.getItem('tag');
  }
  safeDeepSet(payload, 'tags', tag);
  safeDeepSet(payload, 'allBp', allBp);
  if (tag != '-') {
    let wsResult = yield call(
      firebaseInstance.businessPoint.list_tags,
      tag,
      allBp,
      arr_bp
    );
    const arr_list_tags = safeDeepGet(wsResult, 'body', arr_bp);
    safeDeepSet(payload, 'arr_bp', arr_list_tags);
  } else {
    safeDeepSet(payload, 'arr_bp', arr_bp);
  }

  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      const trxMap = {};
      trxMap['payloadTrx'] = payload;
      wsResult = yield call(
        firebaseInstance.transaction.list_pagination,
        payload
      );
      // wsResult = yield call(firebaseInstance.transaction.list, payload);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        const lastDocs = safeDeepGet(wsResult, 'lastDocs', null);
        const lastDocsPrev = safeDeepGet(wsResult, 'lastDocsPrev', null);
        const firstDocs = safeDeepGet(wsResult, 'firstDocs', null);

        for (const orderId in transactionMap) {
          safeDeepSet(transactionMap, [orderId, 'id'], orderId);
          let productSku = safeDeepGet(transactionMap[orderId], [
            'product',
            'sku',
          ]);
          const timeStamp = safeDeepGet(
            transactionMap,
            [orderId, 'time', 'timestamp'],
            {}
          );
          const date = new Date(parseInt(timeStamp)).getDate();
          if (trxMap[date] === undefined) {
            trxMap[date] = { amount_trx: 1 };
          } else {
            trxMap[date]['amount_trx'] += 1;
          }

          let productName = safeDeepGet(
            productMap,
            [productSku, 'name'],
            safeDeepGet(transactionMap[orderId], ['product', 'name'], '-')
          );
          safeDeepSet(transactionMap, [orderId, 'nameProduct'], productName);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(
            businessPointMap,
            [device_id, 'name'],
            device_id
          );
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'timeout'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'expire'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

          // FILTERING
          // if (allBp == 'true') {
          transactionList.push(transactionMap[orderId]);
          // } else if (allBp == 'false') {
          //   if (arr_bp.includes(device_id)) {
          //     transactionList.push(transactionMap[orderId]);
          //   }
          // }
        }

        // const firstData = slicedReversedArray[0] ?? JSON.parse(null);
        // const firstDocs = firstData ? firstData.time.timestamp : null;
        // const lastData = slicedReversedArray[slicedReversedArray.length - 1];
        // const lastDocs = lastData ? lastData.time.timestamp : null;

        // const lastDocsPrev = null;

        const endTs = new Date().getTime();

        logMap['functions'] = 'Report list_transaction';

        logMap['startTs'] = startTs;
        logMap['startTime'] = new Date(startTs + 7 * 60 * 60 * 1000);

        logMap['endTs'] = endTs;
        logMap['endTime'] = new Date(endTs + 7 * 60 * 60 * 1000);

        logMap['trxData'] = trxMap;

        const param = {
          url:
            'https://api-serverless.iotera.io/' + APPLICATION_ID + '/log-trx',
          body: logMap,
        };
        JPOST(param);

        yield put(
          result(LIST, {
            code: Result.SUCCESS,
            body: transactionList,
            lastDocs,
            firstDocs,
            lastDocsPrev,
          })
        );
        return;
      }
      yield put(result(LIST, { result: { code: Result.FAILED } }));
    }
    yield put(result(LIST, { result: { code: Result.FAILED } }));
  }
}

function* nextList(payload) {
  yield put(loading(NEXT_LIST));
  const startTs = new Date().getTime();
  const logMap = {};
  const firebaseInstance = getInstance();
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      safeDeepSet(payload, 'allBp', allBp);
      safeDeepSet(payload, 'arr_bp', arr_bp);
      const productMap = wsResult.body;
      const trxMap = {};
      // trxMap['payloadTrx'] = payload;
      wsResult = yield call(
        firebaseInstance.transaction.next_list_pagination,
        payload
      );
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        const lastDocs = safeDeepGet(wsResult, 'lastDocs', null);
        const lastDocsPrev = safeDeepGet(wsResult, 'lastDocsPrev', null);
        const fistDocs = safeDeepGet(wsResult, 'fistDocs', null);
        for (const orderId in transactionMap) {
          safeDeepSet(transactionMap, [orderId, 'id'], orderId);
          let productSku = safeDeepGet(transactionMap[orderId], [
            'product',
            'sku',
          ]);
          let productName = safeDeepGet(
            productMap,
            [productSku, 'name'],
            safeDeepGet(transactionMap[orderId], ['product', 'name'], '-')
          );
          safeDeepSet(transactionMap, [orderId, 'nameProduct'], productName);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'timeout'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'expire'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

          const timeStamp = safeDeepGet(
            transactionMap,
            [orderId, 'time', 'timestamp'],
            {}
          );
          const date = new Date(parseInt(timeStamp)).getDate();
          if (trxMap[date] === undefined) {
            trxMap[date] = { amount_trx: 1 };
          } else {
            trxMap[date]['amount_trx'] += 1;
          }

          // FILTERING
          // if (allBp == 'true') {
          transactionList.push(transactionMap[orderId]);
          // } else if (allBp == 'false') {
          // if (arr_bp.includes(device_id)) {
          // transactionList.push(transactionMap[orderId]);
          // }
          // }
        }

        const endTs = new Date().getTime();

        logMap['functions'] = 'Report list_transaction';

        logMap['startTs'] = startTs;
        logMap['startTime'] = new Date(startTs + 7 * 60 * 60 * 1000);

        logMap['endTs'] = endTs;
        logMap['endTime'] = new Date(endTs + 7 * 60 * 60 * 1000);

        logMap['trxData'] = trxMap;

        const param = {
          url:
            'https://api-serverless.iotera.io/' + APPLICATION_ID + '/log-trx',
          body: logMap,
        };
        JPOST(param);

        yield put(
          result(NEXT_LIST, {
            code: Result.SUCCESS,
            body: transactionList,
            lastDocs,
            fistDocs,
            lastDocsPrev,
          })
        );
        return;
      }
      yield put(result(NEXT_LIST, { result: { code: Result.FAILED } }));
    }
    yield put(result(NEXT_LIST, { result: { code: Result.FAILED } }));
  }
}

function* read(payload) {}

function* refund(payload) {}

function* summary(payload) {
  yield put(loading(SUMMARY));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const summaryCurrentMap = wsResult.body;
    var payload_prev = payload;
    safeDeepSet(payload_prev, ['month'], safeDeepGet(payload, ['month']) - 1);
    wsResult = yield call(
      firebaseInstance.transaction.nett_settlement,
      payload_prev
    );

    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const summaryPrevMap = wsResult.body;
      wsResult = yield call(firebaseInstance.businessPoint.list);

      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const bpInfo = wsResult.body;
        let landlord_current = sum_month_share(summaryCurrentMap, bpInfo);
        let landlord_prev = sum_month_share(summaryPrevMap, bpInfo);
        let landlord_change = calc_change(landlord_current, landlord_prev);

        let trx_current = sum_month(summaryCurrentMap);
        let trx_prev = sum_month(summaryPrevMap);
        let trx_change = calc_change(trx_current, trx_prev);

        let refund_current = sum_month_refund(summaryCurrentMap);
        let refund_prev = sum_month_refund(summaryPrevMap);
        let refund_change = calc_change(refund_current, refund_prev);

        let settlement_current = sum_settlement(summaryCurrentMap);
        let settlement_prev = sum_settlement(summaryPrevMap);
        let settlement_change = calc_change(
          settlement_current,
          settlement_prev
        );

        const ret_summary = {
          landlord_current: landlord_current,
          landlord_prev: landlord_prev,
          landlord_change: landlord_change,
          trx_current: trx_current,
          trx_prev: trx_prev,
          trx_change: trx_change,
          refund_current: refund_current,
          refund_prev: refund_prev,
          refund_change: refund_change,
          settlement_current: settlement_current,
          settlement_prev: settlement_prev,
          settlement_change: settlement_change,
        };
        yield put(result(SUMMARY, { code: Result.SUCCESS, body: ret_summary }));
        return;
      }
    }
  }
  yield put(result(SUMMARY, { result: { code: Result.FAILED } }));
}

function sum_settlement(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += 1;
    }
  }
  return resultTotal;
}

function* snapshot(payload) {
  yield put(loading(SNAPSHOT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.snapshot.list, payload);

    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const snapshotMap = wsResult.body;
      const snapshotList = [];
      let i = 0;

      for (const orderId in snapshotMap) {
        safeDeepSet(snapshotMap, [orderId, 'id'], i++);
        let device_id = safeDeepGet(snapshotMap[orderId], [
          'product',
          'device_id',
        ]);
        let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');

        safeDeepSet(snapshotMap, [orderId, 'name'], bp_name);
        let dispenseStatus = 'no dispense';
        if (
          safeDeepGet(snapshotMap[orderId], [
            'transactionDetails',
            'isDispense',
          ])
        ) {
          dispenseStatus = 'success';
        }
        safeDeepSet(snapshotMap, [orderId, 'dispensestatus'], dispenseStatus);
        // FILTERING
        snapshotList.push(snapshotMap[orderId]);
      }
      yield put(result(SNAPSHOT, { code: Result.SUCCESS, body: snapshotList }));
      return;
    }
    yield put(result(SNAPSHOT, { result: { code: Result.FAILED } }));
  }
}

function* get_sales_analytics(payload) {
  yield put(loading(GET_SALES_ANALYTICS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const nsMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      var holder = {};
      for (var d in nsMap) {
        var d_val = safeDeepGet(nsMap, [d]);
        let productSku = safeDeepGet(d_val, ['product', 'sku']);
        let productName = safeDeepGet(productMap, [productSku, 'name']);
        safeDeepSet(d_val, ['nameProduct'], productName);
        if (
          safeDeepGet(d_val, ['detail', 'transaction_status']) == 'settlement'
        ) {
          if (holder.hasOwnProperty(d_val.nameProduct)) {
            holder[d_val.nameProduct] = holder[d_val.nameProduct] + 1;
          } else {
            holder[d_val.nameProduct] = 1;
          }
        }
      }
      var ret_sales_analytics = [];
      for (var prop in holder) {
        ret_sales_analytics.push({ name: prop, value: holder[prop] });
      }

      yield put(
        result(GET_SALES_ANALYTICS, {
          code: Result.SUCCESS,
          body: ret_sales_analytics,
        })
      );
      return;
    }
  }
  yield put(result(GET_SALES_ANALYTICS, { result: { code: Result.FAILED } }));
}

function calc_change(sum_current, sum_prev) {
  var temp_change = 0.0;
  if (sum_prev == 0 && sum_current > 0) {
    temp_change = 100.0;
  } else if (sum_prev == 0 && sum_current == 0) {
    temp_change = 0.0;
  } else {
    temp_change = (sum_current / sum_prev - 1.0) * 100.0;
  }
  return temp_change;
}

function sum_month_share(nsMap, bpInfo) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);

    var device_id = safeDeepGet(val, ['product', 'device_id']);
    const share = safeDeepGet(bpInfo, [device_id, 'landlord_share']);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount * share;
    }
  }
  return resultTotal;
}

function sum_month_refund(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'refund') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function sum_month(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function* updateRefund(action) {
  yield put(loading(UPDATE_REFUND));
  const id = safeDeepGet(action, ['payload', 'order_id']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.transaction.updateRefund,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(UPDATE_REFUND, { code }));
}

function* refunded(action) {
  yield put(loading(REFUNDED));
  const payload = safeDeepGet(action, 'payload', {});
  let wsResult = yield call(refundTrx, payload);
  yield call(dummyData, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  const firebaseInstance = getInstance();
  yield call(firebaseInstance.refund.create, action);
  if (code == 200) {
    const order_id = safeDeepGet(action, ['payload', 'id']);
    yield call(firebaseInstance.chat.update, order_id);
    yield put(result(REFUNDED, { code }));
  } else {
    yield put(result(REFUNDED, { code }));
  }
}

// function* refunded(action) {
//   yield put(loading(REFUNDED));
//   let wsResult = yield call(GetDevice);
//   if (safeDeepGet(wsResult, 'code') == 200) {
//     const deviceMap = wsResult.body.data;
//     const deviceList = [];
//     let i = 0;
//     for (const device in deviceMap) {
//       safeDeepSet(deviceMap, [device, 'id'], i++);
//       deviceList.push(deviceMap[device]);
//     }
//     const deviceid = safeDeepGet(action, ['payload', 'device_id']);

//     const data = deviceList.find(({ device_id }) => device_id === deviceid);
//     const token_id = data.token;

//     let Result = yield call(Refund, action, token_id);
//     const code = safeDeepGet(Result, 'code');
//     if (code == 200 || code == 0) {
//       const firebaseInstance = getInstance();
//       yield call(firebaseInstance.refund.create, action);

//       const order_id = safeDeepGet(action, ['payload', 'id']);
//       yield call(firebaseInstance.chat.update, order_id);
//     }
//     yield put(result(REFUNDED, { code }));
//   }
// }

function* detail_transaction(payload) {
  yield put(loading(DETAIL));
  const firebaseInstance = getInstance();
  const orderID = safeDeepGet(payload, 'payload', null);
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      wsResult = yield call(firebaseInstance.transaction.detail, orderID);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        for (const orderId in transactionMap) {
          safeDeepSet(transactionMap, [orderId, 'id'], orderId);
          let productSku = safeDeepGet(transactionMap[orderId], [
            'product',
            'sku',
          ]);
          let productName = safeDeepGet(productMap, [productSku, 'name']);
          safeDeepSet(transactionMap, [orderId, 'nameProduct'], productName);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'timeout'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

          // FILTERING
          transactionList.push(transactionMap[orderId]);
        }

        yield put(
          result(DETAIL, { code: Result.SUCCESS, body: transactionList })
        );
        return;
      }
      yield put(result(DETAIL, { result: { code: Result.FAILED } }));
    }
    yield put(result(DETAIL, { result: { code: Result.FAILED } }));
  }
}

function* refund_list(payload) {
  yield put(loading(REFUND_LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.refund.list, payload);
  const code = safeDeepGet(wsResult, 'code');
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const refundBody = safeDeepGet(wsResult, 'body');
    const refundList = [];
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const businessPointMap = wsResult.body;

    for (const i in refundBody) {
      const device_id = safeDeepGet(refundBody, [i, 'device_id'], '-');
      let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
      safeDeepSet(refundBody, [i, 'nameBp'], bp_name);
      if (allBp == 'true' || allBp == true) {
        refundList.push(refundBody[i]);
      } else {
        if (arr_bp.includes(device_id)) {
          refundList.push(refundBody[i]);
        }
      }
    }
    yield put(result(REFUND_LIST, { code: Result.SUCCESS, body: refundList }));
    return;
  }
  yield put(result(REFUND_LIST, { code: Result.FAILED, body: [] }));
}

function* download_data(payload) {
  yield put(loading(DOWNLOAD_DATA));
  const firebaseInstance = getInstance();
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const fileName = safeDeepGet(payload, 'name', '-');
  let tag = safeDeepGet(payload, 'tags', '-');
  if (tag == '') {
    tag = null;
  }
  safeDeepSet(payload, 'tags', tag);
  safeDeepSet(payload, 'allBp', allBp);
  if (tag != '-') {
    let wsResult = yield call(
      firebaseInstance.businessPoint.list_tags,
      tag,
      allBp,
      arr_bp
    );
    const arr_list_tags = safeDeepGet(wsResult, 'body', arr_bp);
    safeDeepSet(payload, 'arr_bp', arr_list_tags);
  } else {
    safeDeepSet(payload, 'arr_bp', arr_bp);
  }
  safeDeepSet(payload, 'allBp', allBp);
  safeDeepSet(payload, 'multitenancy', MULTITENANCY);
  safeDeepSet(payload, 'application_id', APPLICATION_ID);
  let endPoint = '/getDownloadData';
  if (APPLICATION_ID == '1000000265' || APPLICATION_ID == '1000000276') {
    endPoint = '/getDownloadDataCustom';
  }
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    try {
      const response = yield call(() =>
        axios.post(FIREBASE_URL + endPoint, payload, {
          responseType: 'blob',
          onDownloadProgress: function (progressEvent) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // Gunakan Redux Action melalui closure
            window.dispatchDownloadProgress(progress);
          },
        })
      );

      const buffer = response.data;
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = fileName + '.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);

      yield put(result(DOWNLOAD_DATA, { code: Result.SUCCESS }));
      return;
    } catch (error) {
      yield put(result(DOWNLOAD_DATA, { code: Result.SUCCESS, error: error }));
      return;
    }
  }
}

function* search_data(payload) {
  yield put(loading(SEARCH_DATA));
  const firebaseInstance = getInstance();
  const searchText = safeDeepGet(payload, ['payload', 'text'], null);
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      // wsResult = yield call(searchData, searchText);
      // if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      wsResult = yield call(firebaseInstance.transaction.search, searchText);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        for (const orderId in transactionMap) {
          safeDeepSet(transactionMap, [orderId, 'id'], orderId);
          let productSku = safeDeepGet(transactionMap[orderId], [
            'product',
            'sku',
          ]);
          let productName = safeDeepGet(productMap, [productSku, 'name']);
          safeDeepSet(transactionMap, [orderId, 'nameProduct'], productName);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'timeout'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

          // FILTERING
          transactionList.push(transactionMap[orderId]);
        }
        transactionList.reverse();
        yield put(
          result(SEARCH_DATA, { code: Result.SUCCESS, body: transactionList })
        );
        return;
      }
      yield put(result(SEARCH_DATA, { result: { code: Result.FAILED } }));
    }
    yield put(result(SEARCH_DATA, { result: { code: Result.FAILED } }));
  }
}

const processPaymentMethod = (bpJson, device_id, method, id) => {
  const nettAmount = safeDeepGet(
    bpJson,
    [device_id, 'detail_method', method, 'total_sales'],
    0
  );
  forceDeepSet(
    earning,
    [id.toString(), 'y'],
    (earning[id.toString()].y += nettAmount)
  );

  return {
    amount: nettAmount,
    hpp: safeDeepGet(
      bpJson,
      [device_id, 'detail_method', method, 'total_hpp'],
      0
    ),
    settlement: safeDeepGet(
      bpJson,
      [device_id, 'detail_method', method, 'total_settle'],
      0
    ),
    landlord: safeDeepGet(
      bpJson,
      [device_id, 'detail_method', method, 'total_landlord'],
      0
    ),
  };
};

const calculateEarnings = (dailyBody, arr_bp, allBp, paymentMethod) => {
  let amount = 0,
    mdrAmount = 0,
    settlement = 0,
    landlord = 0,
    amountRefund = 0,
    hpp = 0;
  let earning = Object.fromEntries(
    Array.from({ length: 31 }, (_, i) => [
      i + 1,
      { x: (i + 1).toString().padStart(2, '0'), y: 0 },
    ])
  );
  let product = {},
    bpMap = {};

  if (allBp === 'false') {
    for (const item in dailyBody) {
      let bpJson = safeDeepGet(dailyBody, [item, 'business_point'], {});
      const dateSlice = Number(item.slice(-2));
      if (typeof bpJson === 'string') bpJson = JSON.parse(bpJson);

      for (const device_id of arr_bp) {
        if (!safeDeepGet(bpJson, [device_id])) continue;

        let result = {};
        if (
          ['CASH', 'RFID', 'QRIS', 'NOBU', 'PARTNER'].includes(paymentMethod)
        ) {
          result = processPaymentMethod(
            bpJson,
            device_id,
            paymentMethod,
            dateSlice
          );
        } else {
          const nettAmount = safeDeepGet(
            bpJson,
            [device_id, 'total', 'total_sales'],
            0
          );
          forceDeepSet(
            earning,
            [dateSlice.toString(), 'y'],
            (earning[dateSlice.toString()].y += nettAmount)
          );
          result = {
            amount: nettAmount,
            hpp: safeDeepGet(bpJson, [device_id, 'total', 'total_hpp'], 0),
            settlement: safeDeepGet(
              bpJson,
              [device_id, 'total', 'total_settle'],
              0
            ),
            landlord: safeDeepGet(
              bpJson,
              [device_id, 'total', 'total_landlord'],
              0
            ),
          };
        }

        amount += result.amount;
        hpp += result.hpp;
        settlement += result.settlement;
        landlord += result.landlord;
        amountRefund += safeDeepGet(
          bpJson,
          [device_id, 'total', 'total_refund'],
          0
        );
        product[item] = safeDeepGet(bpJson, [device_id, 'detail_product'], {});
      }
      bpMap[item] = bpJson;
    }
  } else {
    for (const item in dailyBody) {
      const dateSlice = Number(item.slice(-2));
      let result = {};

      if (['CASH', 'RFID', 'QRIS', 'NOBU', 'PARTNER'].includes(paymentMethod)) {
        result = processPaymentMethod(
          dailyBody,
          item,
          paymentMethod,
          dateSlice
        );
      } else {
        const nettAmount = safeDeepGet(
          dailyBody,
          [item, 'total', 'total_sales'],
          0
        );
        forceDeepSet(
          earning,
          [dateSlice.toString(), 'y'],
          (earning[dateSlice.toString()].y += nettAmount)
        );
        result = {
          amount: nettAmount,
          hpp: safeDeepGet(dailyBody, [item, 'total', 'total_hpp'], 0),
          settlement: safeDeepGet(
            dailyBody,
            [item, 'total', 'total_settle'],
            0
          ),
          landlord: safeDeepGet(
            dailyBody,
            [item, 'total', 'total_landlord'],
            0
          ),
        };
      }

      amount += result.amount;
      hpp += result.hpp;
      settlement += result.settlement;
      landlord += result.landlord;
      amountRefund += safeDeepGet(
        dailyBody,
        [item, 'total', 'total_refund'],
        0
      );
      product[item] = safeDeepGet(dailyBody, [item, 'detail_product'], {});
      bpMap[item] = safeDeepGet(dailyBody, [item, 'business_point'], {});
    }
  }
  return {
    amount,
    mdrAmount,
    settlement,
    landlord,
    amountRefund,
    hpp,
    earning,
    product,
    bpMap,
  };
};


function* transactionSaga() {
  yield takeEvery(LIST_BUSINESS_POINTS, list_business_points);
  yield takeEvery(SEARCH_DATA, search_data);
  yield takeEvery(LIST, list);
  yield takeEvery(NEXT_LIST, nextList);
  yield takeEvery(READ, read);
  yield takeEvery(REFUND, refund);
  yield takeEvery(UPDATE_REFUND, updateRefund);
  yield takeEvery(SUMMARY, summary);
  yield takeEvery(SNAPSHOT, snapshot);
  yield takeEvery(GET_SALES_ANALYTICS, get_sales_analytics);
  yield takeEvery(REFUNDED, refunded);
  yield takeEvery(REFUND_LIST, refund_list);
  yield takeEvery(DETAIL, detail_transaction);
  yield takeEvery(SNAPSHOT_MONTHLY, get_snapshot_monthly);
  yield takeEvery(GET_REPORT_GROUP, get_report_group);
  yield takeEvery(DOWNLOAD_DATA, download_data);
}

export default transactionSaga;
