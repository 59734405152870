/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */

/* eslint-disable semi */

/* Import */
import React, { Component } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Pages403 from 'Base/pages/pages-403';
import { NAVBAR, PERMISION, buildTitle } from 'Apps';
import { safeDeepGet } from 'iotera-base/utility/json';
const navbar = NAVBAR;
const permision = PERMISION;
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import Result from 'iotera-base/result';
import { safeGet } from 'iotera-base/utility/json';

import Breadcrumbs from 'Base/components/Common/Breadcrumb';

import { delete_, list, update, create } from '../../store/tag/actions';

import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
const drole = localStorage.getItem('accessNav');
const jsonRole = JSON.parse(drole);
const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');

class TagList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      modalNav: false,
      Role: [],
      isLoading: false,
      columns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },

        {
          dataField: 'key',
          text: 'Key Tag',
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">{row.key ?? row.value}</p>
            </>
          ),
        },
        {
          text: 'Name Tags',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">{row.name}</h5>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, Role) => (
            <div className="d-flex gap-3">
              {guest == 'off' ? (
                <Tippy content={'Delete'}>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      onClick={() => this.handleDeleteClick(Role)}
                    ></i>
                  </Link>
                </Tippy>
              ) : (
                <></>
              )}
            </div>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  componentDidMount() {
    // Load business point
    this.props.LoadTagsList();
    if (safeGet(navbar, ['Management', 'edit'], 'off') == 'off') {
      this.state.columns.pop();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'result', 'code'],
        null
      );
      if (code !== null) {
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          this.showToast();
          this.toggle();
          this.componentDidMount();
        } else {
          this.showToast('failed');
        }
      }
    }
    if (this.props.deleteResult !== prevProps.deleteResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'result', 'code'],
        null
      );
      if (code !== null) {
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          this.showToast('deleteSuccess');
          this.componentDidMount();
        } else {
          this.showToast('deleteFailed');
        }
      }
    }
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleNav() {
    this.setState(prevState => ({
      modalNav: !prevState.modalNav,
    }));
  }

  handleValidSubmit = (e, values) => {
    const name = safeDeepGet(values, 'name', '');
    const key = name.toLowerCase().replace(/\s+/g, '');
    const payload = {
      name,
      key,
      group: 'location',
    };
    this.props.CreateTags(payload);
  };
  /* Handle click */
  handleAddClick = arg => {
    this.toggle();
  };

  handleDeleteClick = row => {
    this.props.DeleteTags(row);
    // this.props.LoadTagsList();
    // this.showToast();
  };
  showToast(msg) {
    var toastType = 'success';
    var message = 'Berhasil Tambah Tags';
    if (msg == 'failed') {
      toastType = 'error';
      message = 'Gagal Tambah Tags';
    } else if (msg == 'deleteSuccess') {
      message = 'Berhasil Delete Tags';
    } else if (msg == 'deleteFailed') {
      toastType = 'error';
      message = 'Gagal Delete Tags';
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeGet(this.props, ['result', 'code'], Result.UNKNOWN_ERROR);
    const list = safeGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const { column, columnBp, bp, nav, name } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Tag List')}</title>
          </MetaTags>
          {safeGet(navbar, ['Business Point', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Tag" breadcrumbItem="List" />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={this.state.columns}
                        data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={this.state.columns}
                            data={list}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitprops.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      {safeGet(
                                        navbar,
                                        ['Management', 'view'],
                                        'off'
                                      ) == 'off' ? (
                                        <></>
                                      ) : (
                                        <Button
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={this.handleAddClick}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Create New Tags
                                        </Button>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          'table align-middle table-nowrap table-hover'
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={this.node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <Modal
                        isOpen={this.state.modal}
                        centered
                        size="md"
                        className={this.props.className}
                        toggle={this.toggle}
                      >
                        <ModalHeader toggle={this.toggle} tag="h4">
                          Create new Tags
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={this.handleValidSubmit}>
                            <Row form>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="name"
                                    label="Nama Tags"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  {guest == 'off' ? 
                                  <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                  disabled={this.state.isLoading}
                                  >
                                    Submit
                                  </button>
                                  : <></>}
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeGet(state, ['tag', 'list'], {}),
  loading: safeGet(state, ['tag', 'loading'], true),
  updateResult: safeGet(state, ['tag', 'update'], {}),
  createResult: safeGet(state, ['tag', 'create'], {}),
  deleteResult: safeGet(state, ['tag', 'delete'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadTagsList: () => dispatch(list()),
  UpdateRole: payload => dispatch(update(payload)),
  DeleteTags: payload => dispatch(delete_(payload)),
  CreateTags: payload => dispatch(create(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
