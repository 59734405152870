/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Label,
  Input,
  Table,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  ModalHeader,
} from 'reactstrap';
// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import {
  list_product,
  deletePlanogram,
} from '../../../SvNestleMilano/store/business-point/actions';
import { read } from '../../../SvNestleMilano/store/product/actions';
// Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Result from 'iotera-base/result';

// Iotera
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
// Redux
import { NAME_PROJECT, buildTitle, APPLICATION_ID } from 'Apps';
import { connect } from 'react-redux';
import {
  readPlanogramPlaystation,
  updatePlanogramPlaystation,
} from '../../store/business-point/actions';
let buildColumns = [];
let buildPlanogramList = {};
//SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert';

import ReactExport from 'react-data-export';
import { CircularProgress } from '@material-ui/core';
import { ModalTitle } from 'react-bootstrap';
import ModalNotification from './Component/ModalNotification';

const remoteList = [
  { value: 'tcl', label: 'TCL' },
  { value: 'samsung', label: 'SAMSUNG' },
  { value: 'SONY', label: 'SONY' },
];

const commandType = [
  { value: 'delay', label: 'Delay' },
  { value: 'send', label: 'Send' },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class PlanogramPlaystation extends Component {
  constructor(props) {
    super(props);
    let isEdit = false;
    const { data, type } = props;
    var deviceId;
    if (APPLICATION_ID == '1000000159') {
      deviceId = safeDeepGet(this.props.match.params, 'id');
    } else {
      deviceId = data;
    }

    const deviceType = type;
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showToast = this.showToast.bind(this);
    this.handlePriceClick = this.handlePriceClick.bind(this);
    this.handleTurnOn = this.handleTurnOn.bind(this);
    this.handleTurnOff = this.handleTurnOff.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleCommandOn = this.toggleCommandOn.bind(this);
    this.togglePlanogram = this.togglePlanogram.bind(this);
    this.toggleCommandOff = this.toggleCommandOff.bind(this);
    this.handleAddRowCommand = this.handleAddRowCommand.bind(this);
    this.handleSelectOnChangeCommand =
      this.handleSelectOnChangeCommand.bind(this);
    this.handleSelectOffChangeCommand =
      this.handleSelectOffChangeCommand.bind(this);
    this.handleInputOffChangeCommand =
      this.handleInputOffChangeCommand.bind(this);
    this.handleInputChangePrice = this.handleInputChangePrice.bind(this);

    buildColumns = productMap => {
      const productList = [];
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      return [
        {
          dataField: 'id',
          text: 'Selection',
          editable: false,
        },
        {
          dataField: 'name',
          text: 'Name',
        },
        {
          dataField: 'price',
          text: 'Price',
          editable: false,
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <Button
                color="primary"
                onClick={() => this.handlePriceClick(row)}
              >
                Price
              </Button>
            </>
          ),
        },
        {
          dataField: 'remote',
          text: 'Remote',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return this.state.remoteList;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{row.remote.toUpperCase()}</p>{' '}
            </>
          ),
        },
        {
          dataField: 'turn_on',
          text: 'Turn On Command',
          editable: false,
          formatter: (cell, row) => (
            <>
              <Button color="primary" onClick={() => this.handleTurnOn(row)}>
                Command
              </Button>
            </>
          ),
        },
        {
          dataField: 'turn_off',
          text: 'Turn Off Command',
          editable: false,
          formatter: (cell, row) => (
            <>
              <Button color="primary" onClick={() => this.handleTurnOff(row)}>
                Command
              </Button>
            </>
          ),
        },
      ];
    };

    const buildPlanogram = (key, planogramMap) => {
      const sensor = safeDeepGet(planogramMap[key], 'sensor');
      const id = safeDeepGet(planogramMap[key], 'id');
      const name = safeDeepGet(planogramMap[key], 'name');
      const send_remote = safeDeepGet(planogramMap[key], 'send_remote');
      const remote_id = safeDeepGet(planogramMap[key], 'remote_id');
      const remote = safeDeepGet(planogramMap[key], 'remote_brand', '');
      const remote_command = safeDeepGet(planogramMap[key], 'remote_command');
      const timer_start = safeDeepGet(remote_command, 'timer_start', '');
      const timer_stop = safeDeepGet(remote_command, 'timer_stop', '');
      const price = safeDeepGet(planogramMap[key], 'price');
      const remote_command_list = safeDeepGet(
        planogramMap[key],
        'remoteCommandList'
      );

      return {
        key: sensor,
        sensor,
        id,
        name,
        send_remote,
        remote_id,
        remote_command,
        timer_start,
        timer_stop,
        remote_command_list,
        price,
        remote,
      };
    };

    buildPlanogramList = planogramMap => {
      return Object.keys(planogramMap).map(key =>
        buildPlanogram(key, planogramMap)
      );
    };
    this.state = {
      deviceType,
      buttonValue: [],
      dataInput: [],
      columns: buildColumns([]),
      planogram: buildPlanogramList([]),
      productMap: {},
      isEdit,
      deviceId,
      rows: [],
      modalPlanogram:false,
      planogram: [],
      modal: false,
      price: [],
      priceMap: {},
      modalCommandOn: false,
      modalCommandOff: false,
      turnOnCommand: [],
      commandOnMap: {},
      turnOffCommand: [],
      commandOffMap: {},
      dynamic_title: '',
      dynamic_description: '',
      confirm_alert: false,
      success_dlg: false,
      isLoading: true,
    };
    this.tableRef = React.createRef();
  }
  showToast() {
    var toastType = 'error';
    var message = 'Pilih product terlebih dahulu';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  togglePlanogram() {
    this.setState(prevState => ({
      modalPlanogram: !prevState.modalPlanogram,
    }));
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleCommandOn() {
    this.setState(prevState => ({
      modalCommandOn: !prevState.modalCommandOn,
    }));
  }
  toggleCommandOff() {
    this.setState(prevState => ({
      modalCommandOff: !prevState.modalCommandOff,
    }));
  }
  handleAddRowCommand = () => {
    const item = {
      type: 'delay',
      value: 0,
    };
    this.setState({
      turnOnCommand: [...this.state.turnOnCommand, item],
    });
  };

  handleInputChangePrice = (e, psId, priceId) => {
    const { name, value } = e.target;
    const { editedRow } = this.state;
    const priceMap = { ...this.state.priceMap };
    const updatedPrice = [...this.state.price];

    updatedPrice[psId][name] = Number(value);
    priceMap[editedRow] = updatedPrice;
    this.updatePriceMapWithNewData(updatedPrice);
    this.setState({ priceMap });
  };

  handleSelectOnChangeCommand = (e, idx, key, type) => {
    const commandOnMap = { ...this.state.commandOnMap };
    const { editedRow } = this.state;

    const updatedCommand = [...this.state.turnOnCommand];
    if (!updatedCommand[idx]) {
      updatedCommand[idx] = {};
    }
    updatedCommand[idx][type] = e.value;
    commandOnMap[editedRow] = updatedCommand;
    this.setState({ commandOnMap });
    this.updateCommandOnMapWithNewData(updatedCommand);
  };

  handleSelectOffChangeCommand = (e, idx, key, type) => {
    const commandOffMap = { ...this.state.commandOffMap };
    const { editedRow } = this.state;

    const updatedCommand = [...this.state.turnOffCommand];
    if (!updatedCommand[idx]) {
      updatedCommand[idx] = {};
    }
    updatedCommand[idx][type] = e.value;
    commandOffMap[editedRow] = updatedCommand;
    this.setState({ commandOffMap });
    this.updateCommandOnMapWithNewData(updatedCommand);
  };

  handleInputChangeCommand = (e, idx) => {
    const { name, value } = e.target;
    const { editedRow } = this.state;
    const updatedCommand = [...this.state.turnOnCommand];
    const commandOnMap = { ...this.state.commandOnMap };

    if (!updatedCommand[idx]) {
      updatedCommand[idx] = {};
    }

    updatedCommand[idx][name] = Number(value);
    commandOnMap[editedRow] = updatedCommand;
    this.setState({ commandOnMap });
    this.updateCommandOnMapWithNewData(updatedCommand);
  };

  handleInputOffChangeCommand = (e, idx) => {
    const { name, value } = e.target;
    const { editedRow } = this.state;
    const updatedCommand = [...this.state.turnOffCommand];
    const commandOffMap = { ...this.state.commandOffMap };

    if (!updatedCommand[idx]) {
      updatedCommand[idx] = {};
    }

    updatedCommand[idx][name] = Number(value);
    commandOffMap[editedRow] = updatedCommand;
    this.setState({ commandOffMap });
    this.updateCommandOnMapWithNewData(updatedCommand);
  };

  updatePriceMapWithNewData = updatedData => {
    const price = [...this.state.price]; // Salin data original

    // Loop melalui updatedData
    Object.keys(updatedData).forEach(psID => {
      Object.keys(updatedData[psID]).forEach(idx => {
        // Pastikan index ada di array price sebelum melakukan update
        if (price[idx]) {
          Object.keys(updatedData[psID][idx]).forEach(key => {
            price[idx][key] = updatedData[psID][idx][key];
          });
        }
      });
    });

    this.setState({ price }); // Simpan kembali ke state
  };
  updateCommandOnMapWithNewData = updatedData => {
    const turnOnCommand = [...this.state.turnOnCommand]; // Salin data original

    // Loop melalui updatedData
    Object.keys(updatedData).forEach(psID => {
      Object.keys(updatedData[psID]).forEach(idx => {
        if (turnOnCommand[idx]) {
          Object.keys(updatedData[psID][idx]).forEach(key => {
            turnOnCommand[idx][key] = updatedData[psID][idx][key];
          });
        }
      });
    });

    this.setState({ turnOnCommand }); // Simpan kembali ke state
  };

  updateCommandOffMapWithNewData = updatedData => {
    const turnOffCommand = [...this.state.turnOffCommand]; // Salin data original

    // Loop melalui updatedData
    Object.keys(updatedData).forEach(psID => {
      Object.keys(updatedData[psID]).forEach(idx => {
        if (turnOffCommand[idx]) {
          Object.keys(updatedData[psID][idx]).forEach(key => {
            turnOffCommand[idx][key] = updatedData[psID][idx][key];
          });
        }
      });
    });

    this.setState({ turnOffCommand }); // Simpan kembali ke state
  };

  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
    //     const formValues = safeDeepGet(
    //   this.props,
    //   ['readPlanogramResult', 'body', 'products'],
    //   []
    // );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const message = safeDeepGet(
        this.props,
        ['createResult', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['createResult', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['createResult', 'message', 'result'],
        Result.UNKNOWN_ERROR
      );
      const ts = safeDeepGet(
        this.props,
        ['createResult', 'ts'],
        new Date().getTime()
      );
      const planogram_history = safeDeepGet(
        this.props,
        ['createResult', 'planogram_history'],
        []
      );
      const name = safeDeepGet(this.props, ['createResult', 'name'], this.state.name);
      this.setState({
        loading: false,
        serverless_code,
        ts,
        planogram_history,
        name,
      });
    }
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const history = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'planogram'],
        []
      );
      const user = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'user'],
        []
      );
      const name = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'name'],
        []
      );
      const timeStamp = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'time_stamp'],
        []
      );
      if (code === Result.SUCCESS) {
        const productMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'products'],
          {}
        );
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const remoteMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'remoteMap'],
          {}
        );
        const remoteList = [];
        const commandList = [];
        for (const i in remoteMap) {
          const remoteData = remoteMap[i];
          const commands = remoteData.commands;

          Object.keys(commands).forEach(j => {
            // Check if the turnOnCommand already exists in commandList
            if (!commandList.some(cmd => cmd.value === j)) {
              commandList.push({ value: j, label: j.toUpperCase() });
            }
          });

          remoteList.push({ value: i, label: i.toUpperCase() });
        }
        this.setState({
          columns: buildColumns(productMap),
          planogram: buildPlanogramList(planogramMap),
          productMap,
          remoteMap,
          commandList,
          remoteList,
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          isLoading: false,
        });
      }
    }
  }

  handlePriceClick = row => {
    const { priceMap } = this.state;
    this.setState({ editedRow: row.sensor });
    // Check if the row's `id` already exists in priceMap
    const existingPrice = priceMap[row.sensor]; // Assume row has an `id` property
    if (existingPrice) {
      this.setState({ price: existingPrice });
    } else {
      // If the data doesn't exist in priceMap, fetch it from row
      const price = [];
      const dataPrice = safeDeepGet(row, 'price', {});
      for (const i in dataPrice) {
        price.push(dataPrice[i]);
      }
      this.setState({ price });
    }

    this.toggle(); // Assuming this.toggle() is for showing a modal or some UI action
  };

  handleTurnOn = row => {
    const { commandOnMap } = this.state;
    this.setState({ editedRow: row.sensor });

    const existingCommand = commandOnMap[row.sensor];
    if (existingCommand) {
      this.setState({ turnOnCommand: existingCommand });
    } else {
      const turnOnCommand = safeDeepGet(row, 'timer_start', []);
      while (turnOnCommand.length < 8) {
        turnOnCommand.push({
          type: 'delay',
          value: 0,
        });
      }
      this.setState({ turnOnCommand });
    }
    this.toggleCommandOn(); // Assuming this toggles a modal or UI update
  };

  handleTurnOff = row => {
    const { commandOffMap } = this.state;
    this.setState({ editedRow: row.sensor });
    console.log(row);

    const existingCommand = commandOffMap[row.sensor];
    if (existingCommand) {
      this.setState({ turnOffCommand: existingCommand });
    } else {
      const turnOffCommand = safeDeepGet(row, 'timer_stop', []);
      console.log(turnOffCommand);
      while (turnOffCommand.length < 8) {
        turnOffCommand.push({
          type: 'delay',
          value: 0,
        });
      }
      this.setState({ turnOffCommand });
    }
    this.toggleCommandOff(); // Assuming this toggles a modal or UI update
  };

  handleSubmit(event) {
    const {
      commandOnMap,
      commandOffMap,
      priceMap,
      remoteMap,
      deviceId,
      deviceType,
    } = this.state;
    this.togglePlanogram();
    const data = this.tableRef.current.props.data;
    const submittedData = {
      data,
      commandOnMap,
      commandOffMap,
      priceMap,
    };
    this.props.AddPlanogram({
      id: deviceId,
      planogram: data,
      device_type: deviceType,
    });
  }
  handlePriceSubmit() {
    // const { deviceId, productMap, dataInput, deviceType } = this.state;
    // const data = this.tableRef.current.props.data;
    // const planogramMap = {};
    // for (const i in data) {
    //   const planogram = data[i];
    //   const selection = planogram['selection'];
    //   const sku = planogram['sku'];
    //   planogramMap[selection] = {
    //     sku,
    //     name: safeDeepGet(productMap, sku, ''),
    //     price: Number(planogram['price']),
    //   };
    // }
    // this.props.AddPlanogram({
    //   id: deviceId,
    //   planogram: planogramMap,
    //   device_type: deviceType,
    // });
  }

  handleChange(i, e) {
    if (e.target.name == 'order') {
      formValues[i][e.target.name] = Number(e.target.value);
    } else {
      formValues[i][e.target.name] = e.target.value;
    }
    this.setState({ formValues });
  }
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  render() {
    const {
      columns,
      planogram,
      history,
      user,
      timeStamp,
      name,
      isLoading,
      price,
    } = this.state;
    const filename = name + '_' + '_' + this.handleValidDate(timeStamp);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle('Planogram')}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Planogram" breadcrumbItem="Edit" />
              {this.state.success_dlg ? (
                <SweetAlert
                  success
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ success_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Planogram Information
                      </CardTitle>
                      <p className="card-title-desc">Product Planogram</p>
                      {isLoading ? (
                        <div className="text-center">
                          <CircularProgress className="text-center"></CircularProgress>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="key"
                            bootstrap4={true}
                            data={planogram}
                            columns={columns}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                            })}
                            bordered={false}
                            ref={this.tableRef}
                          />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">
                          Latest Changes Planogram by
                        </CardTitle>

                        <Row className="align-items-center">
                          <Col lg="9" className="align-self-center">
                            <div className="table-responsive">
                              <Table className="table align-middle table-nowrap table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{user}</td>
                                    <td>
                                      {timeStamp == null
                                        ? '-'
                                        : this.handleValidDate(timeStamp)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>

                          <Col
                            lg="3"
                            className="d-none d-lg-block"
                            align="center"
                          >
                            <div className="clearfix mt-4 mt-lg-0">
                              <ExcelFile
                                filename={filename}
                                element={
                                  <button
                                    type="button"
                                    className="btn btn-primary w-sm"
                                  >
                                    <i className="mdi mdi-download d-block font-size-16"></i>
                                    Download
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={history}
                                  name="Transaction List"
                                >
                                  <ExcelColumn label="Selection" value="key" />
                                  <ExcelColumn label="Product" value="name" />
                                  <ExcelColumn
                                    label="Product SKU"
                                    value="product"
                                  />
                                  <ExcelColumn
                                    label="Product Price"
                                    value="price"
                                  />
                                  <ExcelColumn label="Stock" value="stock" />
                                  <ExcelColumn label="Active" value="active" />
                                </ExcelSheet>
                              </ExcelFile>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {isLoading ? (
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/business-point/list"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button
                          color="primary"
                          onClick={this.componentDidMount}
                        >
                          Refresh
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/business-point/list"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button type="submit" color="primary">
                          Simpan
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
        <Modal
          isOpen={this.state.modal}
          centered
          size="lg"
          className={this.props.className}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle} tag="h4">
            Price
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mb-3">
              <table style={{ width: '90%' }}>
                <tbody>
                  {this.state.price.map((psId, idx) => (
                    <tr key={idx}>
                      <td>
                        <Form encType="multipart/form-data">
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item>
                              <Col sm="4" className="mb-3">
                                <Label htmlFor="name">Name</Label>
                                <Input
                                  type="text"
                                  id="name"
                                  name="name"
                                  value={psId.name}
                                  disabled
                                />
                              </Col>
                              <Col sm="4" className="mb-3">
                                <Label htmlFor="duration">
                                  Duration (minutes)
                                </Label>
                                <Input
                                  type="number"
                                  id="duration"
                                  name="duration"
                                  disabled
                                  value={psId.duration}
                                  onChange={e =>
                                    this.handleInputChangePrice(e, idx, psId.id)
                                  }
                                />
                              </Col>
                              <Col sm="4" className="mb-3">
                                <Label htmlFor="price">Price</Label>
                                <Input
                                  type="number"
                                  id="price"
                                  name="price"
                                  value={psId.price}
                                  onChange={e =>
                                    this.handleInputChangePrice(e, idx, psId.id)
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Submit Button */}
              <Button color="primary" onClick={this.toggle}>
                Save Changes
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalCommandOn}
          centered
          size="lg"
          className={this.props.className}
          toggle={this.toggleCommandOn}
        >
          <ModalHeader toggle={this.toggleCommandOn} tag="h4">
            Command
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mb-3">
              <table style={{ width: '90%' }}>
                <tbody>
                  {this.state.turnOnCommand.map((psId, idx) => (
                    <tr key={idx}>
                      <td>
                        <Form encType="multipart/form-data">
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item>
                              {/* Type Dropdown */}
                              <Col sm="6" className="mb-3">
                                <Label htmlFor="name">Type</Label>
                                <Select
                                  value={
                                    commandType.find(
                                      option => option.value === psId.type
                                    ) || null
                                  }
                                  onChange={e =>
                                    this.handleSelectOnChangeCommand(
                                      e,
                                      idx,
                                      psId.key,
                                      'type'
                                    )
                                  }
                                  options={commandType}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              {/* Command Selection or Input */}
                              {psId.type === 'delay' ? (
                                // If "delay" is selected, show Input field
                                <Col sm="6" className="mb-3">
                                  <Label htmlFor="timing">Timing</Label>
                                  <Input
                                    type="text"
                                    id="value"
                                    name="value"
                                    value={psId.value || ''}
                                    onChange={e =>
                                      this.handleInputChangeCommand(
                                        e,
                                        idx,
                                        psId.key
                                      )
                                    }
                                  />
                                </Col>
                              ) : (
                                // Otherwise, show Select dropdown
                                <Col sm="6" className="mb-3">
                                  <Label htmlFor="timing">Command</Label>
                                  <Select
                                    value={
                                      this.state.commandList.find(
                                        option => option.value === psId.value
                                      ) || null
                                    }
                                    onChange={e =>
                                      this.handleSelectOnChangeCommand(
                                        e,
                                        idx,
                                        psId.key,
                                        'value'
                                      )
                                    }
                                    options={this.state.commandList}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                              )}
                            </Row>
                          </div>
                        </Form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Submit Button */}
              <Button color="primary" onClick={this.toggleCommandOn}>
                Save Changes
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalCommandOff}
          centered
          size="lg"
          className={this.props.className}
          toggle={this.toggleCommandOff}
        >
          <ModalHeader toggle={this.toggleCommandOff} tag="h4">
            Command
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mb-3">
              <table style={{ width: '90%' }}>
                <tbody>
                  {this.state.turnOffCommand.map((psId, idx) => (
                    <tr key={idx}>
                      <td>
                        <Form encType="multipart/form-data">
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item>
                              {/* Type Dropdown */}
                              <Col sm="6" className="mb-3">
                                <Label htmlFor="name">Type</Label>
                                <Select
                                  value={
                                    commandType.find(
                                      option => option.value === psId.type
                                    ) || null
                                  }
                                  onChange={e =>
                                    this.handleSelectOffChangeCommand(
                                      e,
                                      idx,
                                      psId.key,
                                      'type'
                                    )
                                  }
                                  options={commandType}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              {/* Command Selection or Input */}
                              {psId.type === 'delay' ? (
                                // If "delay" is selected, show Input field
                                <Col sm="6" className="mb-3">
                                  <Label htmlFor="timing">Timing</Label>
                                  <Input
                                    type="text"
                                    id="value"
                                    name="value"
                                    value={psId.value || ''}
                                    onChange={e =>
                                      this.handleInputOffChangeCommand(
                                        e,
                                        idx,
                                        psId.key
                                      )
                                    }
                                  />
                                </Col>
                              ) : (
                                // Otherwise, show Select dropdown
                                <Col sm="6" className="mb-3">
                                  <Label htmlFor="timing">Command</Label>
                                  <Select
                                    value={
                                      this.state.commandList.find(
                                        option => option.value === psId.value
                                      ) || null
                                    }
                                    onChange={e =>
                                      this.handleSelectOffChangeCommand(
                                        e,
                                        idx,
                                        psId.key,
                                        'value'
                                      )
                                    }
                                    options={this.state.commandList}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                              )}
                            </Row>
                          </div>
                        </Form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Submit Button */}
              <Button color="primary" onClick={this.toggleCommandOff}>
                Save Changes
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        <ModalNotification
          ts={this.state.ts}
          planogram_history={this.state.planogram_history}
          serverless_code={this.state.serverless_code}
          handleValidDate={this.handleValidDate}
          togglePlanogram={this.togglePlanogram}
          modalPlanogram={this.state.modalPlanogram}
        ></ModalNotification>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramPlaystation'],
    {}
  ),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  readResult: safeDeepGet(state, ['product', 'read'], {}),
  createResult: safeDeepGet(state, [
    'businessPoint',
    'updatePlanogramPlaystation',
  ]),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadProduct: deviceId => dispatch(read(deviceId)),
  DeletePlanogram: payload => dispatch(deletePlanogram(payload)),

  ReadPlanogram: id => dispatch(readPlanogramPlaystation(id)),
  AddPlanogram: payload => dispatch(updatePlanogramPlaystation(payload)),
  // UpdatePlanogram: payload => dispatch(updatePlanogram(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanogramPlaystation);
