/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
  LIST_BALANCE,
  TOGGLE_REFUND,
  MANUAL_UPDATE,
  ADD_PROJECT_HANDLE,
  TOGGLER_MULTI_BALANCE,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import {
  safeDeepDel,
  safeDeepGet,
  safeDeepSet,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  adminListUser,
  deletedUser,
  createdUser,
  get,
  post,
  setProjectHandle,
} from '../../webservice/device';
function checkURL(url) {
  if (url.includes('iotera-vending-multitenancy-2')) {
    return 2;
  } else if (url.includes('iotera-vending-multitenancy-3')) {
    return 3;
  } else if (url.includes('iotera-vending-multitenancy')) {
    return 1;
  } else {
    return 0;
  }
}
function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body', []);
    const mapList = [];
    for (const id in mapBody) {
      const url_balance = safeDeepGet(mapBody, [id, 'url_balance'], '');
      const numberMulti = checkURL(url_balance);
      safeDeepSet(mapBody, [id, 'balance'], 'Loading....');
      safeDeepSet(mapBody, [id, 'appid'], id);
      safeDeepSet(mapBody, [id, 'multitenancy_id'], numberMulti);
      mapList.push(safeDeepGet(mapBody, id));
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: mapList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}
function* list_balance() {
  yield put(loading(LIST_BALANCE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body', []);
    const url_project =
      'https://asia-southeast2-iotera-vending-multitenancy.cloudfunctions.net/projectHandle';
    wsResult = yield call(get, url_project);
    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      const projectBody = safeDeepGet(wsResult, 'body', []);
      const mapList = [];
      for (const id in mapBody) {
        const url_balance = safeDeepGet(mapBody, [id, 'url_balance'], '');
        const numberMulti = checkURL(url_balance);
        safeDeepSet(mapBody, [id, 'multitenancy_id'], numberMulti);
        const url_cms = safeDeepGet(mapBody, [id, 'url_cms'], '');
        const subdomain = url_cms.split('//')[1].split('.')[0];
        const multiBalance = safeDeepGet(
          projectBody,
          [subdomain, 'isMultiBalance'],
          false
        );
        const typeCms = safeDeepGet(
          projectBody,
          [subdomain, 'type'],
          ""
        );
        const shareLandlord = safeDeepGet(
          projectBody,
          [subdomain, 'shareLandlord'],
          ""
        );

        const multitenancy = safeDeepGet(mapBody, [id, 'multitenancy'], false);
        const wsResult = yield call(get, url_balance, id, multitenancy);
        const balance = safeDeepGet(wsResult, ['body', 'balance'], 0);
        const commit_point = safeDeepGet(wsResult, ['body', 'commit_point'], 0);
        safeDeepSet(mapBody, [id, 'balance'], balance);
        safeDeepSet(mapBody, [id, 'commit_point'], commit_point);
        safeDeepSet(mapBody, [id, 'appid'], id);
        safeDeepSet(mapBody, [id, 'multiBalance'], multiBalance);
        safeDeepSet(mapBody, [id, 'typeCMS'], typeCms);
        safeDeepSet(mapBody, [id, 'shareLandlord'], shareLandlord);
        const url_get_auto = safeDeepGet(
          mapBody,
          [id, 'url_get_auto_refund'],
          ''
        );
        const url_toggle_auto = safeDeepGet(
          mapBody,
          [id, 'url_toggle_auto_refund'],
          ''
        );
        const result = yield call(get, url_get_auto, id, multitenancy);
        const conditional = safeDeepGet(result, ['body', 'active'], false);
        safeDeepSet(mapBody, [id, 'autoRefund'], conditional);
        safeDeepSet(mapBody, [id, 'url_toggle'], url_toggle_auto);
        mapList.push(safeDeepGet(mapBody, id));
      }
      yield put(result(LIST_BALANCE, { code: Result.SUCCESS, body: mapList }));
      return;
    }
    yield put(result(LIST_BALANCE, { result: { code: Result.FAILED } }));
  }
  yield put(result(LIST_BALANCE, { result: { code: Result.FAILED } }));
}
function* manualUpdate(payload) {
  yield put(loading(MANUAL_UPDATE));
  const firebaseInstance = getInstance();
  const data = safeDeepGet(payload, 'payload', {});
  const url_manual = safeDeepGet(
    payload,
    ['payload', 'url_manual_balance'],
    ''
  );
  const vm_code = safeDeepGet(payload, ['payload', 'appid'], '-');
  const multitenancy = safeDeepGet(payload, ['payload', 'multitenancy'], false);
  const wsResult = yield call(get, url_manual, vm_code, multitenancy);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(result(MANUAL_UPDATE, { code: code }));
  } else {
    yield put(result(MANUAL_UPDATE, { code: Result.FAILED }));
  }
}
function* toggleRefund(action) {
  yield put(loading(TOGGLE_REFUND));
  const payload = safeDeepGet(action, 'payload', {});
  const url = safeDeepGet(payload, 'url', '');
  safeDeepDel(payload, 'url');
  const wsResult = yield call(post, url, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(result(TOGGLE_REFUND, { code: code }));
  } else {
    yield put(result(TOGGLE_REFUND, { code: Result.FAILED }));
  }
}
function* toggleMultiBalance(action) {
  yield put(loading(TOGGLER_MULTI_BALANCE));
  const payload = safeDeepGet(action, 'payload', {});
  const url = safeDeepGet(payload, 'url', '');
  safeDeepDel(payload, 'url');
  const wsResult = yield call(post, url, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(result(TOGGLER_MULTI_BALANCE, { code: code }));
  } else {
    yield put(result(TOGGLER_MULTI_BALANCE, { code: Result.FAILED }));
  }
}
function* delete_(payload) {
  yield put(loading(DELETE));
  const userUid = safeDeepGet(payload, ['payload', 'uid'], '');
  let wsResult = yield call(deletedUser, userUid);
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(result(DELETE, { code: code }));
  } else {
    yield put(result(DELETE, { code: Result.FAILED }));
  }
}
function* create(action) {
  yield put(loading(CREATE));
  const email = safeDeepGet(action, ['payload', 'email']);
  const pass = safeDeepGet(action, ['payload', 'pass']);
  const wsResult = yield call(createdUser, email, pass);
  const code = safeDeepGet(wsResult, 'code');
  if (code === Result.SUCCESS || code === Result.HTTP.OK) {
    yield put(result(CREATE, { code: Result.SUCCESS }));
  } else {
    yield put(result(CREATE, { code: Result.FAILED }));
  }
}
function* addProjectHandle(action) {
  yield put(loading(ADD_PROJECT_HANDLE));
  const body = safeDeepGet(action, 'payload', {});
  const wsResult = yield call(setProjectHandle, body);
  const code = safeDeepGet(wsResult, 'code');
  if (code === Result.SUCCESS || code === Result.HTTP.OK) {
    yield put(result(ADD_PROJECT_HANDLE, { code: Result.SUCCESS }));
  } else {
    yield put(result(ADD_PROJECT_HANDLE, { code: Result.FAILED }));
  }
}
function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_BALANCE, list_balance);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(CREATE, create);
  yield takeEvery(TOGGLE_REFUND, toggleRefund);
  yield takeEvery(TOGGLER_MULTI_BALANCE, toggleMultiBalance);
  yield takeEvery(MANUAL_UPDATE, manualUpdate);
  yield takeEvery(ADD_PROJECT_HANDLE, addProjectHandle);
}

export default productSaga;
