/* eslint-disable semi */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  EDIT,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
  DOWNLOAD,
  LIST_BALANCE,
  DETAIL,
  SET_PRICE_SUBS,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import {
  forceDeepSet,
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import {
  getBpFee,
  deleteBpFee,
  createBpFee,
  update_time,
  getBp,
  getTags,
  subscription_call,
  tags,
  tags_serverless,
  set_price,
  set_price_toMap,
  get,
  device_list_admin
} from '../../webservice/device';

function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(payload, 'appId');
    const url = safeDeepGet(mapBody, [app_id, 'url_bp_fee'], '');
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], false);
    wsResult = yield call(get, url, app_id, multitenancy);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      const bpFeeBody = wsResult.body;
      const bpFeeList = [];
      const urlBp = safeDeepGet(mapBody, [app_id, 'url_business_point'], '');
      const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], false);
      wsResult = yield call(get, urlBp , app_id , multitenancy);
      const code = safeDeepGet(wsResult, 'code', Result.FAILED);
      const bpMap = {};

      if (code == Result.SUCCESS || code == Result.HTTP.OK) {
        const bpBody = safeDeepGet(wsResult, 'body', []);
        for (const item in bpBody) {
          const device_id = safeDeepGet(
            bpBody[item],
            'device_id',
            safeDeepGet(bpBody[item], 'id', '')
          );
          bpMap[device_id] = bpBody[item];
        }
      }
      const urltags = safeDeepGet(mapBody, [app_id, 'url_get_tag'], '');

      wsResult = yield call(tags_serverless, app_id);
      const tagsBody = safeDeepGet(wsResult, 'body', []);
      const tagsMap = {};
      for (const item in tagsBody) {
        const id = safeDeepGet(tagsBody[item], 'id', '');
        tagsMap[id] = tagsBody[item];
      }
      wsResult = yield call(device_list_admin, app_id)
      const deviceMap = safeDeepGet(wsResult, ['body', 'devices'], {})
      for (const id in deviceMap) {
        const deviceId = safeDeepGet(bpFeeBody, [id, 'device_id'], id);
        const activce_date = safeDeepGet(bpMap, [deviceId, 'active_date'], 0);
        const tags = safeDeepGet(bpFeeBody, [id, 'tags'], '');
        const nameTags = safeDeepGet(tagsMap, [tags, 'name'], '');
        const start_date = safeDeepGet(bpMap, [deviceId, 'start_date'], 0);
        const end_date = safeDeepGet(bpMap, [deviceId, 'end_date'], 0);
        const version = safeDeepGet(deviceMap,[id, 'version', 'version'],  '0.0.0')
        const appsVersion = safeDeepGet(deviceMap, [id, 'version', 'apps_version'], {}) 
        const deviceName = safeDeepGet(deviceMap, [id, 'label'])
        safeDeepSet(bpFeeBody, [deviceId, 'version'],  version)
        safeDeepSet(bpFeeBody, [deviceId, 'appsVersion'],  appsVersion)
        safeDeepSet(bpFeeBody, [deviceId, 'business_point'],  deviceName)
        safeDeepSet(bpFeeBody, [deviceId, 'start_date'], start_date);
        safeDeepSet(bpFeeBody, [deviceId, 'end_date'], end_date);
        safeDeepSet(bpFeeBody, [deviceId, 'active_date'], activce_date);
        safeDel(bpFeeBody, [deviceId, 'tags']);
        safeDeepSet(bpFeeBody, [deviceId, 'tags'], tags);
        safeDeepSet(bpFeeBody, [deviceId, 'tags'], tags);
        safeDeepSet(bpFeeBody, [deviceId, 'device_id'], deviceId);
        bpFeeList.push(bpFeeBody[deviceId]);
      }
      yield put(result(LIST, { code: Result.SUCCESS, body: bpFeeList }));
      return;
    }
    yield put(result(LIST, { result: { code: Result.FAILED } }));
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* list_vm(payload) {
  yield put(loading(LIST_VM));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(payload, 'appId');
    const url = safeDeepGet(mapBody, [app_id, 'url_business_point'], '');
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], '');
    wsResult = yield call(get, url,app_id,multitenancy);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      const bpFeeBody = wsResult.body;
      const bpFeeMap = {};
      for (const id in bpFeeBody) {
        const deviceId = safeDeepGet(bpFeeBody[id], 'device_id', '');
        bpFeeMap[deviceId] = bpFeeBody[id];
      }
      const bpFeeList = [{ label: 'All Points', value: 'all' }];
      const registeredFeeList = [{ label: 'All Points', value: 'all' }];
      // for (const id in bpFeeMap) {
      //   const deviceId = safeDeepGet(bpFeeMap, [id, 'device_id'], '');
      //   const name = safeDeepGet(bpFeeMap, [id, 'name'], '');
      //   bpFeeList.push({label: name, value: deviceId});
      // }
      const url = safeDeepGet(mapBody, [app_id, 'url_bp_fee'], '');
      wsResult = yield call(get, url ,app_id,multitenancy);
      const bodyBpFee = safeDeepGet(wsResult, 'body', {});
      for (const deviceId in bpFeeMap) {
        if (!has(bodyBpFee, deviceId)) {
          const device_id = safeDeepGet(bpFeeMap, [deviceId, 'device_id'], '');
          const name = safeDeepGet(bpFeeMap, [deviceId, 'name'], '');
          bpFeeList.push({ label: name, value: device_id });
        }
      }
      for (const deviceId in bpFeeMap) {
        const device_id = safeDeepGet(bpFeeMap, [deviceId, 'device_id'], '');
        const name = safeDeepGet(bpFeeMap, [deviceId, 'name'], '');
        registeredFeeList.push({ label: name, value: device_id });
      }
      yield put(
        result(LIST_VM, {
          code: Result.SUCCESS,
          body: bpFeeList,
          registered: registeredFeeList,
        })
      );
      return;
    }
    yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}
function* download(payload) {
  yield put(loading(DOWNLOAD));
  const firebaseInstance = getInstance();
  const arr_deviceId = safeDeepGet(payload, ['payload', 'device'], []);
  const arr_name = safeDeepGet(payload, ['payload', 'businessPoint'], []);
  const type = safeDeepGet(payload, ['payload', 'type'], '');
  const wsResult = yield call(firebaseInstance.trxAdmin.listChart);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const trxBody = safeDeepGet(wsResult, 'body', {});
    const data = {};
    const list_data = [];
    for (const i in arr_deviceId) {
      let sum_per_current = sum_per_date(trxBody, arr_deviceId[i], type);
      safeDeepSet(data, [i, 'device_id'], arr_deviceId[i]);
      safeDeepSet(data, [i, 'name'], arr_name[i]);
      safeDeepSet(data, [i, 'data'], sum_per_current);
    }
    for (const i in data) {
      list_data.push(safeDeepGet(data, i, {}));
    }
    yield put(result(DOWNLOAD, { code: Result.SUCCESS, body: list_data }));
    return;
  }
  yield put(result(DOWNLOAD, { result: { code: Result.FAILED } }));
}

function* read(payload) {
  yield put(loading(READ));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(payload, ['payload', 'appId']);
    const device_id = safeDeepGet(payload, ['payload', 'device_id']);
    const type = safeDeepGet(payload, ['payload', 'type']);
    const url = safeDeepGet(mapBody, [app_id, 'url_bp_fee'], '');
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], '');
    wsResult = yield call(get, url ,app_id,multitenancy);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      const bpFeeBody = wsResult.body;
      const minimum_threshold = safeDeepGet(bpFeeBody, [
        device_id,
        'fee',
        'minimum_monthly_threshold',
      ]);
      wsResult = yield call(firebaseInstance.trxAdmin.listChart);
      const code = safeDeepGet(wsResult, 'code', Result.FAILED);
      if (code == Result.SUCCESS || code == Result.HTTP.OK) {
        const trxBody = safeDeepGet(wsResult, 'body', {});
        for (const id in trxBody) {
          const device = safeDeepGet(trxBody, [id, 'product', 'detail'], {});
          let i = 0;
          for (const device_id in device) {
            if (i == 0) {
              safeDeepSet(trxBody, [id, 'device_id'], device_id);
            } else if (i == 1) {
              safeDeepSet(trxBody, [id, 'device_id1'], device_id);
            }
          }
        }
        let sum_per_current = sum_per_date(trxBody, device_id, type);
        let threshold = [];
        let min_threshold = [];
        for (const j in sum_per_current) {
          const amount = safeDeepGet(sum_per_current[j], 'y', 0);
          threshold.push(amount);
          min_threshold.push(minimum_threshold);
        }
        let chart_data = [];

        if (type == 'bruto') {
          chart_data = [
            {
              name: 'Minimum Threshold',
              data: min_threshold,
            },
            {
              name: 'Total',
              data: threshold,
            },
          ];
        } else if (type == 'nett') {
          chart_data = [
            {
              name: 'Total',
              data: threshold,
            },
          ];
        } else if (type == 'profit') {
          chart_data = [
            {
              name: 'Total',
              data: threshold,
            },
          ];
        } else if (type == 'total') {
          chart_data = [
            {
              name: 'Total',
              data: threshold,
            },
          ];
        }
        yield put(result(READ, { code: Result.SUCCESS, body: chart_data }));
        return;
      }
      yield put(result(READ, { result: { code: Result.FAILED } }));
    }
    yield put(result(READ, { result: { code: Result.FAILED } }));
  }
  yield put(result(READ, { result: { code: Result.FAILED } }));
}

function sum_per_date(nsMap, device_id, type) {
  let now = new Date();
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    {
      nsMap ? (now = new Date(val.time.timestamp)) : (now = new Date());
    }
  }
  var resultArr = [];
  var dateArr = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  // const month = now.getMonth();
  // const year = now.getFullYear();
  // const date = new Date(year, month, 1);
  // const dates = [];
  let obj = {};
  // while (date.getMonth() === month) {
  //   const day = new Date(date);
  //   dates.push(('0' + day.getDate()).slice(-2));
  //   date.setDate(date.getDate() + 1);
  // }
  for (const i in dateArr) {
    obj = { x: dateArr[i], y: 0 };
    resultArr.push(obj);
  }

  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    const reference = safeDeepGet(val, ['detail', 'reference_no'], '');
    const split = reference.split('/');
    const tanggal = split[1];
    const splitdate = tanggal.split('-');
    const date = new Date(splitdate[1] + '-' + splitdate[0]);
    // date.setMonth(date.getMonth()-1)
    let val_date = date.toLocaleString(undefined, {
      month: '2-digit',
    });

    var index = dateArr.indexOf(val_date);
    if (safeDeepGet(val, ['product', 'detail', device_id]) != null) {
      if (index == -1) {
        dateArr.push(val_date);
        if (type == 'bruto') {
          // resultArr[Number(val_date) - 1].y += val.payment.amount;
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'bruto_transaction'],
            0
          );
        } else if (type == 'nett') {
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'nett_transaction'],
            0
          );
        } else if (type == 'profit') {
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'iotera_profit'],
            0
          );
        } else if (type == 'total') {
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'total_data'],
            0
          );
        }
      } else {
        if (type == 'bruto') {
          // resultArr[Number(val_date) - 1].y += val.payment.amount;
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'bruto_transaction'],
            0
          );
        } else if (type == 'nett') {
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'nett_transaction'],
            0
          );
        } else if (type == 'profit') {
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'iotera_profit'],
            0
          );
        } else if (type == 'total') {
          resultArr[Number(val_date) - 1].y += safeDeepGet(
            val,
            ['product', 'detail', device_id, 'total_data'],
            0
          );
        }
      }
    }
  }
  return resultArr;
}

function* delete_(action) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(action, ['payload', 'appId'], '');
    const url = safeDeepGet(mapBody, [app_id, 'url_delete_fee'], '');
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], '');
    const device_id = safeDeepGet(action, ['payload', 'id'], '');
    const body = {
      application_id : '100000' + app_id,
      multitenancy: multitenancy,
      id: device_id,
    };
    wsResult = yield call(deleteBpFee, url, body);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      yield put(result(DELETE, { code: Result.SUCCESS }));
      return;
    }
    yield put(result(DELETE, { code: Result.FAILED }));
  }
  yield put(result(DELETE, { code: Result.FAILED }));
}
function* edit(action) {
  yield put(loading(EDIT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(action, ['payload', 'appId'], '');
    const url = safeDeepGet(mapBody, [app_id, 'url_create_fee'], '');
    const device_id = safeDeepGet(action, ['payload', 'deviceid'], 0);
    const device_name = safeDeepGet(action, ['payload', 'business_point'], 0);
    const feeRfid = safeDeepGet(action, ['payload', 'priceRfid'], 0);
    const feeSubs = safeDeepGet(action, ['payload', 'feeSubs'], 0);
    const start_date = safeDeepGet(action, ['payload', 'start_date'], 0);
    const end_date = safeDeepGet(action, ['payload', 'end_date'], 0);
    const category = safeDeepGet(action, ['payload', 'category'], '');
    const tag = safeDeepGet(action, ['payload', 'tag'], {});
    const monthly_threshold = safeDeepGet(
      action,
      ['payload', 'minimum_monthly_threshold'],
      0
    );
    const share_revenue = safeDeepGet(
      action,
      ['payload', 'share_revenue'],
      0
    );
    const type = safeDeepGet(action, ['payload', 'type'], '');
    const fee = {};
    const time = {};
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], '');

    if (type == 'share_revenue') {
      fee['minimum_monthly_threshold'] = Number(monthly_threshold);
      fee['share_revenue'] = Number(1- share_revenue/100);
    } else if (type == 'subscription') {
      fee['rfid'] = Number(feeRfid);
      fee['daily_subscription'] = Number(feeSubs);
    } else if (type == 'monthly_subscription') {
      // time['start_date'] = Number(start_date.getTime());
      // time['end_date'] = Number(end_date.getTime());
      const url_subs = safeDeepGet(mapBody, [app_id, 'url_call_scheduler'], '');
      const bodySubs = {
        url: url_subs,
        tags: tag,
        start_date: Number(start_date),
        application_id:  '100000' + app_id,
        multitenancy: multitenancy,
        end_date: Number(end_date),
        // create_date: new Date().getTime(),
        category: category,
      };
      //
      const result = yield call(subscription_call, bodySubs);
      const result_code = safeDeepGet(result, 'code', Result.FAILED);
      if (result_code != Result.HTTP.OK) {
        yield put(result(CREATE, { code: Result.FAILED }));
        return;
      }
      const url_time = safeDeepGet(mapBody, [app_id, 'url_update_time'], '');

      wsResult = yield call(
        update_time,
        url_time,
        device_id,
        start_date,
        start_date,
        end_date,
        app_id,
        multitenancy
      );
      const code = safeDeepGet(wsResult, 'code', Result.FAILED);
      if (code != Result.HTTP.OK) {
        yield put(result(EDIT, { code: Result.FAILED }));
        return;
      }
    }
    const data = {
      business_point: device_name,
      device_id: device_id,
      fee: fee,
      // time: time,
      tags: tag,
      type: type,
    };
    const body = {
      id: device_id,
      data: data,
      app_id:  '100000' + app_id,
      multitenancy: multitenancy,
    };

    wsResult = yield call(createBpFee, url, body);
    let code = safeDeepGet(wsResult, 'code', Result.FAILED);

    if (code != Result.HTTP.OK) {
      yield put(result(EDIT, { code: Result.FAILED }));
      return;
    }

    yield put(result(EDIT, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(EDIT, { code: Result.FAILED }));
}
function* create(action) {
  yield put(loading(CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(action, ['payload', 'appId'], '');
    const url = safeDeepGet(mapBody, [app_id, 'url_create_fee'], '');
    const businessPointMap = safeDeepGet(action, ['payload', 'business_point']);
    const multitenancy = safeDeepGet(
      mapBody,
      [app_id, 'multitenancy'],
      ''
    );
    for (const i in businessPointMap) {
      const bp = businessPointMap[i];
      const device_id = safeDeepGet(bp, 'value', '');
      const device_name = safeDeepGet(bp, 'label', '');
      const feeRfid = safeDeepGet(action, ['payload', 'priceRfid'], 0);
      const feeSubs = safeDeepGet(action, ['payload', 'feeSubs'], 0);
      const start_date = safeDeepGet(action, ['payload', 'start_date'], null);
      const end_date = safeDeepGet(action, ['payload', 'end_date'], null);
      const tag = safeDeepGet(action, ['payload', 'tag'], '');
      const category = safeDeepGet(action, ['payload', 'category'], '');
      const monthly_threshold = safeDeepGet(
        action,
        ['payload', 'minimum_monthly_threshold'],
        0
      );
      const share_revenue = safeDeepGet(
        action,
        ['payload', 'share_revenue'],
        0
      );
      const type = safeDeepGet(action, ['payload', 'type'], '');
      const fee = {};
      // const time = {};
      if (type == 'share_revenue') {
        fee['minimum_monthly_threshold'] = Number(monthly_threshold);
        fee['share_revenue'] = Number(1 - share_revenue / 100);
      } else if (type == 'subscription') {
        fee['rfid'] = Number(feeRfid);
        fee['daily_subscription'] = Number(feeSubs);
      } else if (type == 'monthly_subscription') {
        // time['start_date'] = Number(start_date.getTime());
        // time['end_date'] = Number(end_date.getTime());
        const url_subs = safeDeepGet(
          mapBody,
          [app_id, 'url_call_scheduler'],
          ''
        );
       

        const bodySubs = {
          url: url_subs,
          tags: tag,
          start_date: Number(start_date),
          end_date: Number(end_date),
          create_date: new Date().getTime(),
          category: category,
          app_id:  '100000' + app_id,
          multitenancy: multitenancy,
        };
        const result = yield call(subscription_call, bodySubs);
        const code = safeDeepGet(result, 'code', Result.FAILED);
        if (code != Result.HTTP.OK) {
          yield put(result(CREATE, { code: Result.FAILED }));
          return;
        }
        const result_update = yield call(
          update_time,
          url_time,
          device_id,
          start_date,
          start_date,
          end_date,
          '100000' + app_id,
          multitenancy,
        );
        const result_update_code = safeDeepGet(
          result_update,
          'code',
          Result.FAILED
        );
        if (result_update_code != Result.HTTP.OK) {
          yield put(result(CREATE, { code: Result.FAILED }));
          return;
        }
      }
      const data = {
        business_point: device_name,
        device_id: device_id,
        fee: fee,
        // time: time,
        tags: tag,
        type: type,
      };
      const body = {
        id: device_id,
        data: data,
        app_id:  '100000' + app_id,
        multitenancy: multitenancy,
      };
      const url_time = safeDeepGet(mapBody, [app_id, 'url_update_time'], '');

      wsResult = yield call(createBpFee, url, body);
      let code = safeDeepGet(wsResult, 'code', Result.FAILED);

      if (code != Result.HTTP.OK) {
        yield put(result(CREATE, { code: Result.FAILED }));
        return;
      }
    }
    yield put(result(CREATE, { code: Result.SUCCESS }));
    return;
  }
  yield put(result(CREATE, { code: Result.FAILED }));
}

function* initCreate(payload) {
  yield put(loading(INIT_CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body', []);
    const app_id = safeDeepGet(payload, 'appId');
    const url = safeDeepGet(mapBody, [app_id, 'url_get_tag'], '');
    // wsResult = yield call(getTags, url);
    wsResult = yield call(tags_serverless, app_id);
    if (safeDeepGet(wsResult, 'code', Result.FAILED) == Result.HTTP.OK) {
      const body = safeDeepGet(wsResult, 'body', []);
      const bodyMap = {}
      for (const i in body) {
        const tag = body[i].name;
        const id = body[i].id;
        safeDeepSet(body[i], 'label', tag);
        safeDeepSet(body[i], 'value', id);
        safeDel(body[i], 'name');
        safeDel(body[i], 'id');
        safeDel(body[i], 'tipe');
        bodyMap[id] = body[i];
      }
      yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: body, options:bodyMap }));
    }
    return;
  }
  yield put(result(INIT_CREATE, { result: { code: Result.FAILED } }));
}

function* detail(payload) {
  yield put(loading(DETAIL));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const app_id = safeDeepGet(payload,['payload', 'appId']);
    const url = safeDeepGet(mapBody, [app_id, 'url_bp_fee'], '');
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], '');
    const device_id = safeDeepGet(payload,['payload', 'device_id']);
    wsResult = yield call(get, url,         app_id,
    multitenancy);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      const bpFeeBody = wsResult.body;
      const data = safeDeepGet(bpFeeBody, device_id, {})
      yield put(result(DETAIL, { code: Result.SUCCESS, body: data }));
      return;
    }
    yield put(result(DETAIL, { result: { code: Result.FAILED } }));
    return;
  }
  yield put(result(DETAIL, { result: { code: Result.FAILED } }));
  return;
}

function* set_price_subs(payload) {
  yield put(loading(SET_PRICE_SUBS));
  const data = safeDeepGet(payload, 'payload', {});
  const price_subs = safeDeepGet(data, 'price_subs', 0 )
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body');
    let app_id = safeDeepGet(payload,['payload', 'appId']);
    const textLength = app_id.length
    if(textLength > 4) {
      app_id = (safeDeepGet(payload,['payload', 'appId'])).substring(textLength-4, textLength)
    }
    const url = safeDeepGet(mapBody, [app_id, 'url_set_price_subs'], '');
    const submitedData = {
      app_id: app_id,
      priceSubs: price_subs
    }
    wsResult = yield call(set_price, url, submitedData);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.SUCCESS || code == Result.HTTP.OK) {
      yield call(set_price_toMap, submitedData)
      yield put(result(SET_PRICE_SUBS, { code: Result.SUCCESS, body: data }));
      return;
    }
    yield put(result(SET_PRICE_SUBS, { result: { code: Result.FAILED } }));
    return;
  }
  yield put(result(SET_PRICE_SUBS, { result: { code: Result.FAILED } }));
  return;
}
function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(LIST_VM, list_vm);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(EDIT, edit);
  yield takeEvery(CREATE, create);
  yield takeEvery(DOWNLOAD, download);
  yield takeEvery(READ, read);
  yield takeEvery(DETAIL, detail);
  yield takeEvery(SET_PRICE_SUBS, set_price_subs);
}

export default productSaga;
