/* eslint-disable no-unused-vars */
import React from "react"
import { Redirect } from "react-router-dom"

// User profile

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import page404 from "../pages/pages-404"

// User profile
import UserProfile from 'Base/pages/Authentication/UserProfile'
import RFID from '../pages/RFID/index'

//Business Point Pages
import BusinessPointList from '../../Base/pages/BusinessPoint'
import BusinessPointInfo from '../../Base/pages/BusinessPoint/info'
// import BusinessPointPlanogram from "../pages/BusinessPoint/planogram"
import BusinessPointPlanogram from '../../Base/pages/BusinessPoint/bodyPlanogram'
//Product Pages
import ProductList from '../pages/Product/index'
import ProductInfoAndroid from '../pages/Product/info_for_levending'
import ProductInfo from '../pages/Product/info'

// Transaction Pages
import TransactionList from '../pages/Transaction/index'
import TransactionReport from '../pages/Transaction/Transaction-report/index'
import SnapshotReport from '../pages/Transaction/Transaction-report/snapshot'
import RefundChat from '../pages/Chat/index'
import DetailChat from '../pages/Chat/detail'
import TransactionDetail from '../pages/Transaction/detail'
import RefundnDetail from '../pages/Chat/detail'
import RefundList from '../pages/Transaction/Transaction-refund/index'
import HistoryPlanogram from '../pages/BusinessPoint/historyPlanogram'

// Transaction Pages
import StockBusinessPoint from '../pages/Stock/BusinessPoints'
import StockWarehouse from '../pages/Stock/Warehouse'
import StockDeliveryOrder from '../pages/Stock/DeliveryOrder'

import RoleList from '../pages/Role/index'
import RoleInfo from '../pages/Role/info'
// Dashboard
import Dashboard from '../pages/Dashboard/index'

// Refiller page
import Refiller from '../pages/Refiller/index'
import CreateRefiller from '../pages/Refiller/create'
import Csv from '../pages/Csv/csv'
// import AdminUser from '../pages/SuperAdmin/User/index'
// import AdminProject from '../pages/SuperAdmin/Project/index'
// Payout Page
import BankAccount from '../pages/Payout/bankAccount'
import PayoutReport from '../pages/Payout/index'
import PayoutRedirect from '../pages/Payout/redirect'
import InfoBank from '../pages/Payout/info'
import AlertList from '../pages/Alert/index'
import AccountList from '../pages/Account/index'
import AccountInfo from '../pages/Account/info'
import SubscriptionDevice from '../pages/Subs/device'
import SubscriptionTags from '../pages/Subs/index'
// import Page404 from '../../Base/pages/pages-404'
import NotificationPage from '../pages/Notification/index'
import ErrorCode from '../pages/Error/index'

import couponList from '../pages/Coupon/index'
import DiscountList from '../pages/Discount/index'
import BusinessPointTag from '../pages/Tag/index'
// Category Page
import CategoryList from '../pages/Category/index'
import BusinessPointCalibration from '../pages/BusinessPoint/Calibrations'
import infoShareRevenue from "Base/pages/BusinessPoint/infoShareRevenue"

import InvoiceList from "../pages/Invoicing/index"
import InvoiceInfo from "../pages/Invoicing/info"
import RFIDList from "../pages/RFID/list"
import { safeDeepGet } from "iotera-base/utility/json"
let role = ''
const obj = JSON.parse(localStorage.getItem('accessNav'))

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: '/payout/redirect/:id', component: PayoutRedirect },

]
const authRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/csv', component: Csv },
  // { path: '/alert/list', component: AlertList },
  { path: '/alert/:type/list', component: AlertList },
  { path: '/alert/history/:id', component: AlertList },
  { path: '/alert/device/:id', component: AlertList },
  { path: '/alert/device-history/:device_id/:key', component: AlertList },
  { path: '/notification/:type/list', component: NotificationPage },
  //profile
  { path: '/profile', component: UserProfile },
  { path: '/role', component: RoleList },
  { path: '/role/create', component: RoleInfo },
  { path: '/role/edit/:id', component: RoleInfo },
  { path: '/business-point/options/:id', component: infoShareRevenue },

  { path: '/account/list', component: AccountList },
  { path: '/account/create', component: AccountInfo},
  { path: '/account/edit/:id', component: AccountInfo },

  { path: '/subs/device', component: SubscriptionDevice},
  { path: '/subs/list', component: SubscriptionTags},
  
  /* Business point */
  { path: '/business-point/list', component: BusinessPointList },
  { path: '/business-point/create', component: BusinessPointInfo },
  { path: '/business-point/info/:id', component: BusinessPointInfo },
  { path: '/business-point/planogram/:type/:id', component: BusinessPointPlanogram },
  { path: '/business-point/planogram/:id', component: BusinessPointPlanogram },
  { path: '/business-point/history/planogram', component: HistoryPlanogram },
  { path: '/business-point/tag', component: BusinessPointTag},

  { path: '/coupon/list', component: couponList },
  { path: '/discount/list', component: DiscountList },
  // product
  { path: '/product/list', component: ProductList },
  { path: '/product/create', component: ProductInfo },
  { path: '/product/create/android', component: ProductInfoAndroid },
  { path: '/product/edit/:id', component: ProductInfo },
  { path: '/product/edit/android/:id', component: ProductInfoAndroid },

  // transaction
  { path: '/transaction/list', component: TransactionList },
  { path: '/transaction/report', component: TransactionReport },
  { path: '/transaction/snapshot', component: SnapshotReport },
  { path: '/transaction/chat', component: RefundChat },
  { path: '/transaction/chat/detail/:id', component: DetailChat },
  { path: '/transaction/detail/:id', component: TransactionDetail },
  { path: '/refund/detail/:id', component: RefundnDetail },
  { path: '/refund/list', component: RefundList },

  //Refiller
  { path: '/refill/list', component: Refiller },
  { path: '/refill/create', component: CreateRefiller },

  // stock
  { path: '/stock/business-point', component: StockBusinessPoint },
  { path: '/stock/warehouse', component: StockWarehouse },
  { path: '/stock/create-do', component: StockDeliveryOrder },

  // Payout
  { path: '/payout/list', component: PayoutReport },
  { path: '/payout/bankaccount', component: BankAccount },
  { path: '/payout/create/bankaccount', component: InfoBank },
  { path: '/payout/edit/:id', component: InfoBank },
  // { path: "*", component: page404 },

  // Category
  { path: '/category/list', component: CategoryList},

  { path: '/error/code', component: ErrorCode},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/rfid/list/:id', component: RFID },

  // Invoice
  {path: '/invoice/list', component: InvoiceList},
  {path: '/invoice/:id/info', component: InvoiceInfo},
  { path: '/rfid', component: RFIDList},

  {
    path: '/',
    exact: true,
    component: () =>
      safeDeepGet(obj, ['Overview', 'view'], 'off') == 'on' ? (
        <Redirect to="/dashboard" />
      ) :  safeDeepGet(obj, ['Business Point', 'view'], 'off') == 'on' ? (
        <Redirect to="/business-point/list" />
      ) : safeDeepGet(obj, ['Product', 'view'], 'off') == 'on' ? (
        <Redirect to="/product/list" />
      ) :  safeDeepGet(obj, ['Transaction', 'view'], 'off') == 'on' ? (
        <Redirect to="/transaction/list" />
      ) :  safeDeepGet(obj, ['Activity', 'view'], 'off') == 'on' ? (
        <Redirect to="/refund/list" />
      ) :  safeDeepGet(obj, ['Management', 'view'], 'off') == 'on' ? (
        <Redirect to="/role" />
      ) :  safeDeepGet(obj, ['Payout', 'view'], 'off') == 'on' ? (
        <Redirect to="/payout/list" />
      ) : (
        <Redirect to="/" />
      ),
  },  
]
export {  publicRoutes, authRoutes }
