/* eslint-disable semi */
import React from 'react';
// Strap
import { Row, Col, Modal, ModalBody, ModalHeader, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import PropTypes from 'prop-types';
import { safeDeepGet } from 'iotera-base/utility/json';

class PriceEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClik: false,
      modal: false,
    };
  }

  static propTypes = {
    value: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    onClik: PropTypes.bool,
    modal: PropTypes.bool,
    row: PropTypes.string,
    onPriceChange: PropTypes.func,
  };
  static defaultProps = {
    value: 0,
  };
  getValue = (e, values) => {
    const { onUpdate, row, onPriceChange } = this.props;
    const selection = safeDeepGet(row, 'selection', '');
    const product = safeDeepGet(row, 'product', '');
    const amountHpp = Number(safeDeepGet(values, 'hpp', 0));
    const amountShare = Number(safeDeepGet(values, 'share', 0));
    const price = amountHpp + amountShare;
    const body = {
      selection: selection,
      product: product,
      Hpp: amountHpp,
      Share: amountShare,
      total: price,
    };
    onPriceChange(body);
    onUpdate(price);
    return price;
  };
  handleOnClick = () => {
    this.setState({ onClik: true });
  };

  render() {
    const { modal, row } = this.props;
    return [
      <Modal
        key="modal"
        size="lg"
        isOpen={this.state.onClik ? false : modal}
        // unmountOnClose={this.getValue}
        toggle={() => this.setState({ onClik: true })}
      >
        <ModalHeader toggle={() => this.setState({ onClik: true })} tag="h4">
          {'Set Price'}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={this.getValue}>
            <Row>
              <div className="mb-3">
                <Label className="mt-1" htmlFor="selectAccount">
                  HPP :
                </Label>
                <AvField
                  type="number"
                  id="hpp"
                  name="hpp"
                  value={safeDeepGet(row, 'hpp', 0)}
                  placeholder="Masukan HPP..."
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <Label className="mt-1" htmlFor="selectAccount">
                  {'Margin'}
                </Label>
                <AvField
                  type="number"
                  id="share"
                  name="share"
                  value={safeDeepGet(row, 'share', 0)}
                  placeholder={'Masukan ' + 'Margin' + ' ....'}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
            </Row>

            <Row>
              <Col>
                <div className="text-end"></div>
              </Col>
              <Col>
                <div className="text-end">
                  {/* <button
                      onClick={this.handleOnClick}
                      className="btn btn-danger"
                    >
                      Cancel
                    </button> */}
                  <button type="submit" className="btn btn-success save-user">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>,
    ];
  }
}

export default PriceEditor;
