/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
// Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import { buildTitle, orderOptions, buttonList } from 'Apps';
// Redux
import { connect } from 'react-redux';
import {
  readPlanogramRetail,
  updatePlanogramRetailOld,
  list_history,
} from '../../store/business-point/actions';

import ReactExport from 'react-data-export';
import { CircularProgress } from '@material-ui/core';
import ModalNotification from './Component/ModalNotification';
import VMLayout from './Component/VMLayout';
import ColumnLayout from './Component/ColumnLayout';
import columnBuilder from './Component/ColumnBuilder';
import ColumnsPrice from './Component/ColumnPriceBuilder';
import column2Builder from './Component/Column2Builder';

let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let buildPlanogramList = {};
let buildColumnList = {};
let removed = [];

let dataInput = [];
class BusinessPointPlanogram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonValue: [],
      dataInput,
      confirm_alert: false,
    };

    const buildPlanogram = (selection, planogramMap) => {
      const product = safeDeepGet(planogramMap, [selection, 'product'], '');
      const nameProduct = safeDeepGet(
        planogramMap,
        [selection, 'nameProduct'],
        ''
      );
      const price = safeDeepGet(planogramMap, [selection, 'price'], 0);
      const stock = safeDeepGet(planogramMap, [selection, 'stock'], 0);
      const column = safeDeepGet(
        planogramMap,
        [selection.slice(1, 4), 'column'],
        ''
      );
      if (column) {
        var text = column.split(',');
        var arr_column = [];
      }
      for (const i in text) {
        arr_column.push({
          label: text[i],
          value: text[i],
        });
      }
      // this.state.dataInput['1'] = arr_column;
      this.state.dataInput[selection] = arr_column;
      const active =
        safeDeepGet(planogramMap, [selection, 'active'], false) === true
          ? 'on'
          : 'off';
      var stockColumn = 0;
      for (const value in arr_column) {
        const stock = safeDeepGet(
          planogramMap,
          [arr_column[value].value, 'stock'],
          0
        );
        stockColumn += stock;
      }
      return {
        key: selection,
        selection,
        product,
        nameProduct,
        price,
        stock,
        active,
        column,
        arr_column,
        stockColumn,
      };
    };
    buildPlanogramList = planogramMap => {
      const planogramList = [];
      const columnList = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'column'],
        []
      );
      for (const i in columnList) {
        const column = columnList[i];
        planogramList.push(buildPlanogram(column, planogramMap));
      }
      return planogramList;
    };
    buildColumnList = (planogramMap, productMap) => {
      const columnButtonList = [];
      const butttonList = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'button'],
        []
      );
      for (const i in butttonList) {
        const button = butttonList[i];
        columnButtonList.push(
          buildColumnButton(button, planogramMap, productMap)
        );
      }
      return columnButtonList;
    };
    const buildColumnButton = (selection, planogramMap, productMap) => {
      const column = safeDeepGet(planogramMap, [selection, 'column'], '');
      const product = safeDeepGet(planogramMap, [selection, 'product'], '');
      const nameProduct = safeDeepGet(
        planogramMap,
        [selection, 'nameProduct'],
        ''
      );
      const price = safeDeepGet(planogramMap, [selection, 'price'], 0);
      const order = safeDeepGet(planogramMap, [selection, 'order'], 0);

      const active =
        safeDeepGet(planogramMap, [selection, 'active'], false) === true
          ? 'on'
          : 'off';

      if (column) {
        var text = column.split(',');
        var arr_column = [];
      }
      for (const i in text) {
        arr_column.push({
          label: text[i].toString(),
          value: text[i].toString(),
        });
      }
      const { dataInput } = this.state;
      dataInput[selection] = arr_column;
      var stockColumn = 0;
      for (const value in arr_column) {
        const stock = safeDeepGet(
          planogramMap,
          [arr_column[value].value, 'stock'],
          0
        );
        stockColumn += stock;
      }

      return {
        key: selection,
        selection,
        column,
        dataInput,
        arr_column,
        product,
        nameProduct,
        price,
        order,
        active,
        stockColumn,
      };
    };
    const { data, type } = props;
    const deviceId = safeDeepGet(data, 'deviceId');
    const deviceType = type;
    const massage = safeDeepGet(this.props, ['result', 'message'], {});
    this.state = {
      listButton: buttonList,
      buttonValue: [],
      dataInput,
      massage,
      deviceId,
      columns: columnBuilder({}),
      columnsPrice: ColumnsPrice({}),
      deviceType,
      columnsButton: column2Builder({}),
      planogram: buildPlanogramList({}),
      // buttonColumns: buildColumnList({}),
      button: buildColumnList({}),
      productMap: {},
      isLoading: true,
      serverless_code: 20,
      modalPlanogram: false,
      planogram_history: [],
    };

    this.tableRef = React.createRef();
    this.tableSlave1Ref = React.createRef();
    this.tableSlave2Ref = React.createRef();
    this.buttonRef = React.createRef();
    this.priceRef = React.createRef();

    this.handleCancel = this.handleCancel.bind(this);
    // this.handleOnClick = this.handleOnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.togglePlanogram = this.togglePlanogram.bind(this);
  }
  togglePlanogram() {
    this.setState(prevState => ({
      modalPlanogram: !prevState.modalPlanogram,
    }));
  }

  handleImage = arg => {
    window.open(arg);
  };
  get_history() {
    const { deviceId } = this.state;
    const payload = {
      device_id: deviceId,
    };
    this.props.GetHistory(payload);
  }
  async componentDidMount() {
    const { deviceId } = this.state;
    const response = await axios.get(
      'https://asia-southeast2-iotera-vending.cloudfunctions.net/getTime'
    );
    const body = response.data;
    this.setState({
      timeStampNow: body.timestamp,
    });
    this.props.ReadPlanogram(deviceId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        const productMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'products'],
          {}
        );
        const imageMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'images'],
          {}
        );
        const columnOptions = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'columnOptions'],
          []
        );
        const waitResult = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'waitResult'],
          true
        );
        const buttonOptions = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'buttonOptions'],
          []
        );
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const history = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'planogram'],
          []
        );
        const name = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'name'],
          []
        );
        const timeStamp = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'time_stamp'],
          0
        );
        const user = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'user'],
          []
        );
        // console.log(planogramMap)
        this.setState({
          columns: columnBuilder(productMap, imageMap),
          columnsButton: column2Builder(
            productMap,
            imageMap,
            buttonOptions,
            orderOptions,
            this.handleOnChange
          ),
          columnsPrice: ColumnsPrice(productMap),
          planogram: buildPlanogramList(planogramMap),
          button: buildColumnList(planogramMap, productMap),
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          productMap,
          imageMap,
          columnOptions,
          buttonOptions,
          planogramMap,
          waitResult,
          isLoading: false,
        });
      }
    }
    if (this.props.resultHistory !== prevProps.resultHistory) {
      const code = safeDeepGet(
        this.props,
        ['resultHistory', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['resultHistory', 'body']);
        if (body != null) {
          const history = safeDeepGet(body[0], 'planogram', []);
          const name = safeDeepGet(body[0], 'name', '');
          const timeStamp = safeDeepGet(body[0], 'time_stamp', 0);
          const user = safeDeepGet(body[0], 'user', '');
          this.setState({
            history: history,
            name: name,
            timeStamp: timeStamp,
            timeStampDataBase: timeStamp,
            user: user,
          });
        }
      }
    }
    if (this.props.result !== prevProps.result) {
      const code = safeDeepGet(
        this.props,
        ['result', 'code'],
        Result.UNKNOWN_ERROR
      );

      const message = safeDeepGet(
        this.props,
        ['result', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['result', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['result', 'message', 'result'],
        20
      );

      const ts = safeDeepGet(
        this.props,
        ['result', 'ts'],
        new Date().getTime()
      );
      const planogram_history = safeDeepGet(
        this.props,
        ['result', 'planogram_history'],
        []
      );
      const name = safeDeepGet(this.props, ['result', 'name'], this.state.name);
      const mssage_serveless =
        'Message: ' + serverless_text + ' error code ' + serverless_code;
      this.setState({
        loading: false,
        serverless_code,
        ts,
        planogram_history,
        name,
      });
      setTimeout(() => {
        if (code === Result.SUCCESS) {
          // this.showToast('Update Planogram Successful', code, mssage_serveless);
          // setTimeout(() => window.location.reload(), 2000);
          this.componentDidMount();
        } else if (code !== Result.UNKNOWN_ERROR) {
          // this.showToast('Update Planogram Failed', code, mssage_serveless);
        }
      }, 2000);
    }
  }
  showToast(messagee, code, txt) {
    if (messagee == 'Update Planogram Successful') {
      var toastType = 'success';
      var message = messagee;
    } else {
      var toastType = 'error';
      var message = messagee + ' Kode Error: ' + code + ' ' + txt;
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleCancel(event) {
    history.push('/business-point/list');
  }
  handleOnClick(item) {}
  handleOnChange(value, selection) {
    const { listButton, buttonValue, dataInput } = this.state;
    const selectedValue = [];
    for (const i in value) {
      selectedValue.push(value[i]);
    }
    buttonValue[selection] = selectedValue;
    dataInput[selection] = selectedValue;

    safeDeepGet(buttonValue[selection], []);
    if (value.length == 0) {
      listButton.splice(0, 0, ...removed);
      removed = [];
    }
    for (const key in value) {
      var index = listButton.indexOf(value[key]);

      if (index !== -1) {
        const remove = listButton.splice(index, 1);
        removed.push(value[key]);
      }
    }
  }
  handleOkClick = () => {
    const { dataPlanogram } = this.state;
    this.props.UpdatePlanogram(dataPlanogram);
    this.setState({
      confirm_alert: false,
      dynamic_title: 'Updated',
      dynamic_description: 'Planogram sudah di update',
    });
  };
  handleSubmit(event) {
    this.get_history();
    this.togglePlanogram();
    this.setState({
      loading: true,
    });
    const {
      deviceId,
      productMap,
      dataInput,
      imageMap,
      dataPrice,
      timeStampNow,
      deviceType,
      waitResult,
    } = this.state;
    const type = safeDeepGet(this.props, ['readPlanogramResult', 'type'], []);
    const dataMaster = this.tableRef.current.props.data;
    const dataSlave1 = this.tableSlave1Ref.current.props.data;
    const dataSlave2 = this.tableSlave2Ref.current.props.data;
    const button = this.buttonRef.current.props.data;
    const data = [...dataMaster, ...dataSlave1, ...dataSlave2];
    const planogramMap = {};
    for (const i in data) {
      const planogram = data[i];
      const selection = planogram['selection'];
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ',';
        }
      }
      planogramMap[selection] = {
        stock: Number(planogram['stock']),
        active: planogram['active'] == 'on',
      };
    }
    for (const i in button) {
      const planogram = button[i];
      const selection = planogram['selection'];
      const order = planogram['order'];
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ',';
        }
      }

      const product = planogram['product'];
      var image = safeDeepGet(imageMap, product);
      if (image == '') {
        image =
          'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64';
      }
      const active = planogram['active'];

      planogramMap[selection] = {
        order: Number(order),
        column: str,
        product,
        name: safeDeepGet(productMap, product, '-'),
        image: image,
        price: Number(planogram['price']),
        active: planogram['active'] == 'on',
      };
    }
    this.setState({
      dataPlanogram: {
        id: deviceId,
        planogram: planogramMap,
        type: deviceType,
        device_type: deviceType,
        latest_planogram: this.state.planogramMap,
        dataPrice,
        waitResult,
      },
    });
    setTimeout(
      function () {
        const body = safeDeepGet(this.props, ['resultHistory', 'body'], []);
        const tsData = safeDeepGet(body[0], 'time_stamp', 0);
        if (tsData == 0) {
          this.props.UpdatePlanogram(this.state.dataPlanogram);
        } else if (timeStampNow > tsData) {
          this.props.UpdatePlanogram(this.state.dataPlanogram);
        } else {
          this.setState({
            confirm_alert: true,
          });
        }
        //Start the timer
      }.bind(this),
      2000
    );
  }
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };

  render() {
    const loading = safeDeepGet(this.state, 'loading', false);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    const {
      columns,
      columnsPrice,
      planogram,
      columnsButton,
      dataInput,
      button,
      history,
      name,
      timeStamp,
      user,
      isLoading,
      serverless_code,
      ts,
      planogram_history,
    } = this.state;

    const table1Data = planogram.filter(
      item => item.selection >= 1 && item.selection <= 60
    );
    const table2Data = planogram.filter(
      item => item.selection >= 101 && item.selection <= 160
    );
    const table3Data = planogram.filter(
      item => item.selection >= 201 && item.selection <= 260
    );

    const dataButton = [];
    for (const selection in dataInput) {
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ', ';
        }
      }
      if (selection.slice(0, 1) != 'K') {
        dataButton.push({ key: selection, value: str });
      }
    }
    const columnList = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'column'],
      []
    );
    const type = safeDeepGet(this.props, ['readPlanogramResult', 'type'], []);
    const dataColumn = [];
    for (const selection in columnList) {
      const column = columnList[selection];
      dataColumn.push({ key: column, value: str });
    }

    const filename = name + '_' + '_' + this.handleValidDate(timeStamp);
    const windowDimension = window.innerWidth;
    const isMobile = windowDimension <= 640;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Planoggram')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Business Point" breadcrumbItem="Planogram" />
            <Row>
              <Col lx="8">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5">
                      Stock & Penempatan Produk
                    </CardTitle>
                    {/* <div>
                      <p>
                        Untuk penggunaan double spiral, cukup setting planogram
                        pada salah satu spiral saja.
                      </p>
                    </div> */}
                    {isLoading ? (
                      <div className="text-center">
                        <CircularProgress className="text-center"></CircularProgress>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField="selection"
                          // data={planogram}
                          columns={columnsButton}
                          data={button}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                          })}
                          bordered={false}
                          // ref={this.tableRef}
                          ref={this.buttonRef}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Row>
                  <ColumnLayout dataButton={dataButton} />
                </Row>
                <Row></Row>
                <div
                  style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '80px',
                    height: '50px',
                    zIndex: 999,
                  }}
                >
                  <Row>
                    <Col
                      sm="6"
                      className="mt-3"
                      style={{
                        padding: '0px',
                        width: '200px',
                      }}
                      s
                    >
                      <p className="text-start" style={{ fontSize: '10px' }}>
                        Latest Planogram{' '}
                        {timeStamp == null
                          ? '-'
                          : this.handleValidDate(timeStamp)}
                      </p>
                    </Col>
                    <Col
                      sm="6"
                      style={{
                        padding: '0px',
                        width: '200px',
                      }}
                    >
                      {isLoading ? (
                        <div className="d-flex flex-wrap gap-2 float-end ">
                          <Link
                            to="/business-point/list"
                            color="secondary"
                            className="btn btn-secondary"
                            role="button"
                            disabled={loading}
                          >
                            Cancel
                          </Link>{' '}
                          <Button
                            color="primary"
                            onClick={() => this.componentDidMount()}
                            disabled={loading}
                          >
                            Refresh
                          </Button>
                        </div>
                      ) : (
                        <div className="d-flex flex-wrap gap-2 float-end ">
                          <Link
                            to="/business-point/list"
                            color="secondary"
                            className="btn btn-secondary"
                            role="button"
                            disabled={loading}
                          >
                            Cancel
                          </Link>{' '}
                          {guest == 'off' ? (
                            <Button
                              color="primary"
                              onClick={this.handleSubmit}
                              disabled={loading}
                            >
                              Simpan
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5"></CardTitle>
                    {isLoading ? (
                      <div className="text-center">
                        <CircularProgress className="text-center"></CircularProgress>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <h4 className="mb-3 text-center">Stock Master (001-060)</h4>
                        <BootstrapTable
                          keyField="selection"
                          bootstrap4={true}
                          data={table1Data}
                          columns={columns}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                          })}
                          bordered={false}
                          ref={this.tableRef}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5"></CardTitle>
                    {isLoading ? (
                      <div className="text-center">
                        <CircularProgress className="text-center"></CircularProgress>
                      </div>
                    ) : (
                      <div className="table-responsive ">
                        <h4 className="mb-3 text-center">Stock Slave 1 (101-160)</h4>
                        <BootstrapTable
                          keyField="selection"
                          bootstrap4={true}
                          data={table2Data}
                          columns={columns}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                          })}
                          bordered={false}
                          ref={this.tableSlave1Ref}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5"></CardTitle>
                    {isLoading ? (
                      <div className="text-center">
                        <CircularProgress className="text-center"></CircularProgress>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <h4 className="mb-3 text-center">Stock Slave 2 (201-260)</h4>
                        <BootstrapTable
                          keyField="selection"
                          bootstrap4={true}
                          data={table3Data}
                          columns={columns}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                          })}
                          bordered={false}
                          ref={this.tableSlave2Ref}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              {/* <Col xl="4">
                <Row>
                  <VMLayout type={type} dataColumn={dataColumn} />
                </Row>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5">
                      Latest Changes Planogram by
                    </CardTitle>

                    <Row className="align-items-center">
                      <Col lg="9" className="align-self-center">
                        <div className="table-responsive">
                          <Table className="table align-middle table-nowrap table-hover mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{user}</td>
                                <td>
                                  {timeStamp == null
                                    ? '-'
                                    : this.handleValidDate(timeStamp)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>

                      <Col lg="3" className="d-none d-lg-block" align="center">
                        <div className="clearfix mt-4 mt-lg-0">
                          <ExcelFile
                            filename={filename}
                            element={
                              <button
                                type="button"
                                className="btn btn-primary w-sm"
                              >
                                <i className="mdi mdi-download d-block font-size-16"></i>
                                Download
                              </button>
                            }
                          >
                            <ExcelSheet data={history} name="Planogram">
                              <ExcelColumn label="Selection" value="key" />
                              <ExcelColumn label="Product" value="name" />
                              <ExcelColumn
                                label="Product SKU"
                                value="product"
                              />
                              <ExcelColumn
                                label="Product Price"
                                value="price"
                              />
                              <ExcelColumn label="Stock" value="stock" />
                              <ExcelColumn label="Active" value="active" />
                              <ExcelColumn label="Column" value="column" />
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.confirm_alert ? (
              <SweetAlert
                title="Terdapat data planogram baru"
                warning
                showCancel
                confirmButtonText="Ya, Update dengan data baru!"
                cancelBtnText="Tidak, Resfresh page"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => this.handleOkClick()}
                onCancel={() => window.location.reload()}
              >
                Apakah anda yakin akan melakukan update data?
              </SweetAlert>
            ) : null}

            <ModalNotification
              ts={ts}
              planogram_history={planogram_history}
              serverless_code={serverless_code}
              handleValidDate={this.handleValidDate}
              togglePlanogram={this.togglePlanogram}
              modalPlanogram={this.state.modalPlanogram}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramRetail'],
    {}
  ),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  result: safeDeepGet(state, ['businessPoint', 'updatePlanogramRetailOld'], {}),
  resultHistory: safeDeepGet(state, ['businessPoint', 'list_history'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadPlanogram: id => dispatch(readPlanogramRetail(id)),
  GetHistory: payload => dispatch(list_history(payload)),
  UpdatePlanogram: payload => dispatch(updatePlanogramRetailOld(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPointPlanogram);
