/* eslint-disable no-unused-vars */
// components/columnBuilder.js

import React from 'react'
import { Type } from 'react-bootstrap-table2-editor'
import { Input } from 'reactstrap' // Import Input component if using reactstrap or similar library
import { safeDeepGet } from 'iotera-base/utility/json'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'


const column2Builder = (productMap, imageMap, buttonOptions, orderOptions, handleOnChange) => {
  const productList = []
  const animatedComponents = makeAnimated()
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FDC8C8',
      fontSize: '13px',
    }),
  }
  const styles = {
    control: (base, state) => ({
      ...base,
      fontSize: '13px',
    }),
  }
  for (const sku in productMap) {
    productList.push({
      label: productMap[sku],
      value: sku,
    })
  }
  return [
    {
      editable: false,
      dataField: 'image',
      text: 'Image',

      formatter: (cell, row, rowIndex, imageMap) => (
        <>
          <img
            src={safeDeepGet(imageMap, row.product, '')}
            onClick={() =>
              this.handleImage(safeDeepGet(imageMap, row.product, ''))
            }
            width="50"
            height="50"
          />
        </>
      ),

      formatExtraData: imageMap,
      style: (cell, row, rowIndex, colIndex, imageMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
    {
      dataField: 'selection',
      text: 'Button',
      editable: false,
      // headerStyle:{
      //   width: 20
      // },
      formatter: (cell, row, rowIndex, productMap) => (
        <>
          <p>{row.selection}</p>
        </>
      ),
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
            width: '50px',
          }
        }
        return {
          width: '50px',
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },

    {
      dataField: 'product',
      text: 'Product',
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return productList
        },
      },
      formatter: (cell, row, rowIndex, productMap) => (
        <>
          <p>{safeDeepGet(productMap, row.product, '')}</p>
        </>
      ),

      formatExtraData: productMap,
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
            width: '150px',
          }
        }
        return {
          width: '150px',
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
    {
      dataField: 'order',
      text: 'Order',
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return orderOptions
        },
      },
      formatter: (cell, row, rowIndex, productMap) => (
        <>
          <p>{row.order}</p>
        </>
      ),
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
    {
      dataField: 'price',
      text: 'Price (Rp)',
      validator: (newValue, row, column) => {
        if (!/^\d+$/.test(newValue)) {
          return {
            valid: false,
          }
        }
        return true
      },
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
            width: '50px',
          }
        }
        return {
          width: '50px',
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },

    {
      dataField: 'column',
      text: 'Column',
      editable: false,

      formatter: (cell, row, rowIndex, productMap) => (
        <>
          <Select
            defaultValue={row.dataInput[row.selection]}
            components={animatedComponents}
            closeMenuOnSelect={false}
            onChange={value => handleOnChange(value, row.selection)}
            options={buttonOptions}
            isLoading={false}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Piih tombol..."
            styles={row.stockColumn <= 3 ? customStyles : styles}
          />
        </>
      ),

      formatExtraData: productMap,
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },

      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
    {
      dataField: 'active',
      text: 'Active',
      editor: {
        type: Type.CHECKBOX,
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <Input
            type="checkbox"
            defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
          />
        )
      },
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
            width: '50px',
          }
        }
        return {
          width: '50px',
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stockColumn <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          }
        }
      },
    },
  ]
}

export default column2Builder
