/* eslint-disable semi */
/* eslint-disable no-unused-vars */
// components/columnBuilder.js

import React from 'react'
import { Type } from 'react-bootstrap-table2-editor'
import { Input } from 'reactstrap' // Import Input component if using reactstrap or similar library
import { safeDeepGet } from 'iotera-base/utility/json';

const  columnBuilderXy = (productMap, imageMap) => {
  const productList = [];
  const windowDimension = window.innerWidth;
  const isMobile = windowDimension <= 640;
  for (const sku in productMap) {
    productList.push({
      label: productMap[sku],
      value: sku,
    });
  }

  const column = [
    {
      dataField: 'selection',
      text: 'Selection',
      editable: false,
      headerStyle: {
        width: 20,
      },
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },

      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
    },
    {
      editable: false,
      dataField: 'image',
      text: 'Image',
      headerStyle: {
        width: 50,
      },
      formatter: (cell, row, rowIndex, imageMap) => (
        <>
          <img
            src={safeDeepGet(imageMap, row.product, '')}
            onClick={() =>
              this.handleImage(safeDeepGet(imageMap, row.product, ''))
            }
            width="50"
            height="50"
          />
        </>
      ),

      formatExtraData: imageMap,
      style: (cell, row, rowIndex, colIndex, imageMap) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
    },
    {
      dataField: 'product',
      text: 'Product',
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return productList;
        },
      },
      formatter: (cell, row, rowIndex, productMap) => (
        <>
          <p>{safeDeepGet(productMap, row.product, '')}</p>
        </>
      ),

      formatExtraData: productMap,
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
            width: '150px',
          };
        }
        return {
          width: '150px',
        };
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
    },
    {
      dataField: 'price',
      text: 'Price (Rp)',
      // editorRenderer: (
      //   editorProps,
      //   value,
      //   row,
      //   column,
      //   rowIndex,
      //   columnIndex
      // ) => <PriceEditor {...editorProps} value={value} />,

      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
    },
    {
      dataField: 'stock',
      text: 'Stock',
      headerStyle: {
        width: 80,
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Stock harus angka',
          };
        }
        // if (newValue > maxStock) {
        //   return {
        //     valid: false,
        //     message: 'Stock tidak bisa melebihi 40'
        //   };
        // }
        return true;
      },
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
    },

    {
      dataField: 'active',
      text: 'Active',
      editor: {
        type: Type.CHECKBOX,
      },
      headerStyle: {
        width: 20,
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <Input
            type="checkbox"
            defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
          />
        );
      },
      style: (cell, row, rowIndex, colIndex, productMap) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => {
        if (row.stock <= 3) {
          return {
            backgroundColor: '#FDC8C8',
          };
        }
      },
    },
  ];

  return column;
}

export default columnBuilderXy
