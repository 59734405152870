// components/columnBuilder.js

import React from 'react'
import { Type } from 'react-bootstrap-table2-editor'
import { Input } from 'reactstrap' // Import Input component if using reactstrap or similar library

const columnBuilder = (productMap) => {
  const productList = []
  const windowDimension = window.innerWidth
  const isMobile = windowDimension <= 640

  for (const sku in productMap) {
    productList.push({
      label: productMap[sku],
      value: sku,
    })
  }

  const mobile = [
    {
      dataField: 'selection',
      text: 'Selection',
      editable: false,
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
    {
      dataField: 'product',
      text: 'Product',
      editor: {
        type: Type.SELECT,
        getOptions: () => productList,
      },
      formatter: (cell, row, rowIndex, productMap) => (
        <p>{productMap[row.product] || ''}</p>
      ),
      formatExtraData: productMap,
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
    {
      dataField: 'price',
      hidden: 'true',
      text: 'Price (Rp)',
      validator: (newValue) => /^\d+$/.test(newValue) ? true : { valid: false },
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
    {
      dataField: 'stock',
      text: 'Stock',
      validator: (newValue) => /^\d+$/.test(newValue) ? true : { valid: false },
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
    {
      dataField: 'active',
      text: 'Active',
      hidden: 'true',
      editor: {
        type: Type.CHECKBOX,
      },
      formatter: (cell, row) => (
        <Input
          type="checkbox"
          defaultChecked={row.active === 'on'}
        />
      ),
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
  ]

  const pc = [
    {
      dataField: 'selection',
      text: 'Selection',
      editable: false,
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
    {
      dataField: 'stock',
      text: 'Stock',
      validator: (newValue) => /^\d+$/.test(newValue) ? true : { valid: false },
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
    {
      dataField: 'active',
      text: 'Active',
      editor: {
        type: Type.CHECKBOX,
      },
      formatter: (cell, row) => (
        <Input
          type="checkbox"
          defaultChecked={row.active === 'on'}
        />
      ),
      style: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
      editCellStyle: (cell, row) => row.stock <= 3 ? { backgroundColor: '#FDC8C8' } : {},
    },
  ]

  return isMobile ? mobile : pc
}

export default columnBuilder
